import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label, Popup } from 'semantic-ui-react'

import PlaystoreMessage from '../socmed/PlaystoreMessage';
import PlaystorePublishing from '../socmed/PlaystorePublishing';
import PlaystoreReporting from '../socmed/PlaystoreReporting';
import PlaystoreNetwork from '../socmed/PlaystoreNetwork';

class PlaystoreBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      return(
        <div style={{display: 'flex', padding: '0 1em', minHeight: screenHeight}}>
          <div style={{flex: 1, background: '#f5f5f5', padding: '1em 1em'}}>
            <Header as='h5'>
              <Header.Content>
                Google Play Store API
                <Header.Subheader>Play store publishing and interaction API.</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />

            {mode==0 && <div style={{textAlign: 'center', paddingTop: screenHeight/2}}><Icon name='google play' style={{fontSize: '32em', color: '#fff'}} /></div>}
            {mode==1 && <PlaystoreMessage />}
            {mode==2 && <PlaystorePublishing />}
            {mode==3 && <PlaystoreReporting />}
            {mode==4 && <PlaystoreNetwork />}
          </div>
          <div style={{paddingLeft: '0', background: '#f0f0f0', padding: '1em 1em'}}>
            <Popup content='Play Store Conversation' position='left center' inverted trigger={<Label color={mode==1 ? 'green' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 1)}><Icon name='comments' size='large' style={{margin: '0'}} /></Label>} />
            <Divider />
            <Popup content='Play Store Publishing' position='left center' inverted trigger={<Label color={mode==2 ? 'green' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 2)}><Icon name='code' size='large' style={{margin: '0'}} /></Label>} />
            <Divider />
            <Popup content='Play Store Reporting' position='left center' inverted trigger={<Label color={mode==3 ? 'green' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 3)}><Icon name='file alternate outline' size='large' style={{margin: '0'}} /></Label>} />
            <Divider />
            <Popup content='Play Store Downloader' position='left center' inverted trigger={<Label color={mode==4 ? 'green' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 4)}><Icon name='users' size='large' style={{margin: '0'}} /></Label>} />
          </div>
        </div>
      )
    }
}

export default PlaystoreBody

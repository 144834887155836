import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

import MenuLeftOutbound from '../../../libraries/realm/halo/MenuLeftOutbound'
import ModalContainer from '../../../libraries/common/ModalContainer';

import DashboardBody from '../stream/DashboardBody'
import CampaignStatisticBody from './CampaignStatisticBody';

import ConversationBody from '../stream/ConversationBody'
import ActivityBody from '../stream/ActivityBody'
import EventBody from '../stream/EventBody'
import ResourceBody from './ResourceBody'

import DeductBody from './DeductBody';
import TopupBody from './TopupBody';
import RecordBody from './RecordBody';
import RatesBody from './RatesBody';

import RecordingBody from './RecordingBody';
import EngagementBody from './EngagementBody';

import { connect } from "react-redux"
import { checkToken } from "../../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class RealmOutboundBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      mode: 'dashboard',
    }
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  render() {
    const { data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}
    const { mode, module, screenWidth, screenHeight, mini, modal }= this.props
    
    return (
      <div style={{display: 'flex'}}>
        <div style={{background: '#ccc', minHeight: screenHeight-300, borderRight: '1px solid #fff'}}>
          <MenuLeftOutbound openWindow={this.props.openWindow.bind(this)} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini} setModule={this.props.setModule.bind(this)}/>
        </div>

        <div style={{flex: '1', marginLeft: mini ? '2px' : '80px', marginRight: '2px', padding: '.85em .5em'}}>
          {mode==='dashboard' && <DashboardBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} />}
          {mode==='conversation' && <ConversationBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} checkToken={data} />}
          {mode==='activity' && <ActivityBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module}  checkToken={data} />}
          
          {mode==='event' && <EventBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)} params={this.props.params} />}
          {mode==='resource' && <ResourceBody openWindow={this.props.openWindow.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight} module={module} />}
          
          {modal==='deduct' && <ModalContainer size='small' content={<DeductBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} /> }
          {modal==='topup' && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} /> }
          {modal==='record' && <ModalContainer size='small' content={<RecordBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} /> }
          {modal==='rates' && <ModalContainer size='small' content={<RatesBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} /> }

          {modal==='recording' && <ModalContainer size='small' content={<RecordingBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)}  params={this.props.params} />} closeModal={this.props.closeModal.bind(this)} /> }
          {modal==='engagement' && <ModalContainer size='small' content={<EngagementBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)}  params={this.props.params} />} closeModal={this.props.closeModal.bind(this)} /> }
          
          {modal==='campaign_statistic' && <ModalContainer size='large' content={<CampaignStatisticBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)}  params={this.props.params} />} closeModal={this.props.closeModal.bind(this)} /> }
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RealmOutboundBody)
import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label, Popup } from 'semantic-ui-react'

import FacebookMessage from '../socmed/FacebookMessage';
import FacebookPage from '../socmed/FacebookPage';
import FacebookGroup from '../socmed/FacebookGroup';
import FacebookNetwork from '../socmed/FacebookNetwork';

class FacebookBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      return(
        <div style={{display: 'flex', padding: '0 1em', minHeight: screenHeight}}>
          <div style={{flex: 1, background: '#f5f5f5', padding: '1em 1em'}}>
            <Header as='h5'>
              <Header.Content>
                Facebook V9+ API
                <Header.Subheader>Facebook page, content and interaction API.</Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />
            
            {mode==0 && <div style={{textAlign: 'center', paddingTop: screenHeight/2}}><Icon name='facebook f' style={{fontSize: '32em', color: '#fff'}} /></div>}
            {mode==1 && <FacebookMessage />}
            {mode==2 && <FacebookPage />}
            {mode==3 && <FacebookGroup />}
            {mode==4 && <FacebookNetwork />}
          </div>
          <div style={{paddingLeft: '0', background: '#f0f0f0', padding: '1em 1em'}}>
            <Popup content='Facebook Messenger/Conversation' position='left center' inverted trigger={<Label color={mode==1 ? 'blue' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 1)}><Icon name='comments' size='large' style={{margin: '0'}} /></Label>} />
            <Divider />
            <Popup content='Facebook Page Management' position='left center' inverted trigger={<Label color={mode==2 ? 'blue' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 2)}><Icon name='file powerpoint' size='large' style={{margin: '0'}} /></Label>} />
            <Divider />
            <Popup content='Facebook Group Management' position='left center' inverted trigger={<Label color={mode==3 ? 'blue' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 3)}><Icon name='clone outline' size='large' style={{margin: '0'}} /></Label>} />
            <Divider />
            <Popup content='Facebook Friend Networks' position='left center' inverted trigger={<Label color={mode==4 ? 'blue' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 4)}><Icon name='users' size='large' style={{margin: '0'}} /></Label>} />
          </div>
        </div>
      )
    }
}

export default FacebookBody

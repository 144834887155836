import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label, Popup } from 'semantic-ui-react'

class InstagramMessage extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      return(
        <div>
          <Grid>
            <Grid.Row width={16}>
              <Grid.Column width={6}>
                <Header as='h5'>
                  <Header.Content>
                    Instagram <b>Message</b>
                    <Header.Subheader>Recent direct message, commentary.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>
                  
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h5'>
                  <Header.Content>
                    Instagram <b>Message List</b>
                    <Header.Subheader>Detail conversation message / chat.</Header.Subheader>
                  </Header.Content>
                </Header>
                <Segment>

                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      )
    }
}

export default InstagramMessage

import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
} from 'semantic-ui-react'

import { ivrPath } from "../../../libraries/common/_lib"

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse } from "date-fns"
import { } from "../../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseInteractive, statusInteractive } from "../../../actions/flow/interactiveAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    interactiveStore: state.interactiveStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseInteractive:(objParam) => dispatch(browseInteractive(objParam)),
  }
}

class InteractiveContent extends Component {
  constructor(props) {
    super(props)
    this.state= {
      greetingMode: false,
    }
  }

  componentDidMount() {
    // const { search, paging }= (this.props.interactiveStore.browseInteractive!==undefined ? this.props.interactiveStore.browseInteractive : {})
    // this.props.browseInteractive({search: {transactDate: format(new Date(), 'yyyy-MM-dd 00:00:00'), ...search}, paging: paging})
  }

  showGreeting() {
    const { greetingMode }= this.state
    
    this.setState({
      greetingMode: !greetingMode
    })
  }
  
  render() {
    const { screenWidth, screenHeight, interactiveId, clientId, params }= this.props
    // console.log(navigations)

    let seq= 0, seqX= 0;
    const greetings= params.greetings || []
    const navigations= params.navigations || []
    const navItems= []
    
    let i= 0;
    while (i<=9) {
      if (navigations && navigations[i] && i!=0) {
        navItems.push(navigations[i]);
        let j= i * 10;
        while (j<((i+1) * 10)) {
          if (navigations[j]) {
            navItems.push(navigations[j]);
            let k= j * 10;
            while (k<((j+1) * 10)) {
              if (navigations[k]) {
                navItems.push(navigations[k]);
              }
              k++;
            }
          }
          j++;
        }
      } else if (navigations && navigations[i]) {
        navItems.push(navigations[i]);
      } else if (i==0) {
        navItems.push({
          level: 0,
        });
      }
      i++;
    }

    return (<>
      <div style={{display: 'flex', padding: '1em 1em'}}>
        <div style={{flex: '1'}}>
          <Header as='h4' style={{marginTop: '.25em'}}>
            <Icon name='sliders horizontal' style={{fontSize: '1em'}}/>
            <Header.Content>
              IVR Menu Content
            </Header.Content>
          </Header>
        </div>
        <div>
          {/* <Button size='mini' color='red' onClick={this.props.setMode.bind(this, '2',this.props.interactiveId, this.props.clientId)} icon style={{padding: '.5em'}}><Icon name='pencil alternate' /></Button> */}
          <Button size='mini' color='grey' onClick={this.showGreeting.bind(this)} icon style={{padding: '.5em'}}><Icon name={this.state.greetingMode==true? 'angle up' : 'angle down'} /></Button>
        </div>
      </div>

      <div style={{padding: '1em 1em', minHeight: screenHeight}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell width={6} style={{background: '#ebe0d9'}}>
                <Header as='h5' color='blue'><b>IVR MENU</b></Header>
              </Table.HeaderCell>
              <Table.HeaderCell width={10}>
                <Header as='h5' color='blue'>CONTENT / SCRIPT</Header>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Header as='h5' color='blue'></Header>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { navItems.map((item, key)=>{
                if (item) {
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell style={{background: '#fbf7f0'}}>
                        <Header as='h5'>
                          {/* <Icon name={item.responsed=='Agent' ?'spy' : 'fax' } color={item.responsed=='Agent' ?'blue' : 'orange' } circular inverted style={{fontSize: '1.2em'}}/> */}
                          <Header.Content>
                            <b>{item.name}</b>
                            <Header.Subheader>Level: <b>{item.level}</b> / Resp. by: <b>{item.responsed}</b></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.scriptTalent}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <ReactAudioPlayer
                          style={{
                            width: '210px',
                            display: 'block',
                            height: '35px',
                          }}
                          src={clientId ? ivrPath + clientId.toString().padStart(6,"0") + item.level.toString().padStart(2,"0") +'.mp3' : null}
                          controls
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                } else {
                  return(<></>);
                }
              })
            }
          </Table.Body>
        </Table>

        {this.state.greetingMode && 
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width={6} style={{background: '#faf5e4'}}>
                  <Header as='h5' color='blue'><b>GREETING</b></Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={10}>
                  <Header as='h5' color='blue'>CONTENT / SCRIPT</Header>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Header as='h5' color='blue'></Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { greetings && Object.keys(greetings).map((key)=>{
                  if (key) {
                    return(
                      <Table.Row key={++seqX}>
                        <Table.Cell>{seqX}</Table.Cell>
                        <Table.Cell style={{background: '#fefcf0'}}>
                          <Header as='h5'>
                            <Icon name='fax' color='orange' circular inverted style={{fontSize: '1.2em'}}/>
                            <Header.Content>
                              <b>{(key=='scriptBackPrevious' && 'Back to Previous Menu') || (key=='scriptRepeatCurrent' && 'Repeat Current Menu') || (key=='scriptBusy' && 'Agent is Busy / Not Available') || (key=='scriptOffline' && 'Agent is Offline') || (key=='scriptProblem' && 'System has Problem') || (key=='scriptThankyou' && 'Thank You / Good Bye')}</b>
                              <Header.Subheader><b>Greetings</b></Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            <Header.Content>
                              {greetings[key]}
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <ReactAudioPlayer
                            style={{
                              width: '210px',
                              display: 'block',
                              height: '35px',
                            }}
                            src={clientId ? ivrPath + clientId.toString().padStart(6,"0") + ((key=='scriptBackPrevious' && 'previous') || (key=='scriptRepeatCurrent' && 'current') || (key=='scriptBusy' && 'busy') || (key=='scriptOffline' && 'offline') || (key=='scriptProblem' && 'problem') || (key=='scriptThankyou' && 'thankyou')) +'.mp3' : null}
                            controls
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  } else {
                    return(<></>);
                  }
                })
              }
            </Table.Body>
          </Table>
        }
        <Segment textAlign='right' style={{marginTop: '0'}}>
          <Button type='button' icon labelPosition='left' color='red' onClick={this.props.setMode.bind(this, '2',this.props.interactiveId, this.props.clientId)}><Icon name='setting' />CONFIG</Button>
        </Segment>
      </div>
    </>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InteractiveContent)

import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image } from 'semantic-ui-react'

export default class MenuLeftMiddleware extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      
    };
  }

  render() {
    const { screenWidth,  screenHeight, mini, module}= this.props
    
    if (mini) {
      return(
        <></>
      )
    } else {
      return (
        <div style={{top:'3em', position: 'fixed', height: screenHeight, width: '80px', zIndex: 99, background: '#dadadaaa'}}>
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '80px', padding: '3.5em 1.2em 0 0'}}>
            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Dashboard' content='Real time monitoring channel, traffic and system heartbeat.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'dashboard')}
                  color={this.props.mode=='dashboard' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='dashboard' ? '600' : '100'}}>
                    <Icon name='chart line' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='dashboard' ? 'red' : 'grey'}/>
                </Header>
              } />
            </div>
            
            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Service Traffic' content='Service on demand traffic based on request activity, which triggered by API.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'traffic')}
                  color={this.props.mode=='traffic' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='traffic' ? '600' : '100'}}>
                    <Icon name='comments outline' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='traffic' ? 'red' : 'grey'}/>
                </Header>
              } />

              <Divider style={{margin: '1em  0'}}/>
              <Popup header='API Logger' content='Activity API which saved for audit trail or security alert.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'API logger')}
                  color={this.props.mode=='API logger' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='API logger' ? '600' : '100'}}>
                    <Icon name='file alternate outline' style={{margin: '0', fontSize: '1.4em'}} color={this.props.mode=='API logger' ? 'red' : 'grey'}/>
                </Header>
              } />
            </div>

            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='OTP Service' content='Send OTP through preferred available channels, voice, sms, whatsapp and email. Testing and development purposes only.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'OTP service')}
                  color={this.props.mode=='OTP service' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='OTP service' ? '600' : '100'}}>
                    <Icon name='hashtag' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='OTP service' ? 'red' : 'grey'}/>
                </Header>
              } />

              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Comm Service' content='API communication service sandbox for testing and development purpose.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'Comm service')}
                  color={this.props.mode=='Comm service' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='Comm service' ? '600' : '100'}}>
                    <Icon name='phone volume' style={{margin: '0', fontSize: '1.5em'}} color={this.props.mode=='Comm service' ? 'red' : 'grey'}/>
                </Header>
              } />

              {/* <Divider style={{margin: '1em  0'}}/>
              <Popup header='API Service' content='API service sandbox for testing and development purpose.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'API service')}
                  color={this.props.mode=='API service' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='host' ? '600' : '100'}}>
                    <Icon name='code' style={{margin: '0', fontSize: '1.1em'}} color={this.props.mode=='API service' ? 'red' : 'grey'}/>
                </Header>
              } /> */}

            </div>
            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='API Inventory' content='Available API for autonomous management and content delivery.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'API inventory')}
                  color={this.props.mode=='API inventory' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='API inventory' ? '600' : '100'}}>
                    
                    <Icon name='fire' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='API inventory' ? 'red' : 'grey'}/>
                </Header>
              } />
            </div>
          </div>
        </div>
      );
    }
  }
}

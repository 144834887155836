import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { browseRates } from "../../actions/halo/ratesAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    ratesStore: state.ratesStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseRates:(objParam) => dispatch(browseRates(objParam)),
  }
};


class RatesBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {
      column: 'voice'
    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.ratesStore.browseRates!==undefined ? this.props.ratesStore.browseRates : {})
    this.props.browseRates(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.ratesStore.browseRates!==undefined ? this.props.ratesStore.browseRates : {})
    this.props.browseRates({search: {...search}, paging: paging})
  }

  setColumn(v) {
    this.setState({
      column: v
    })
  }

  render() {
    const { column }= this.state
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.ratesStore.browseRates!==undefined ? this.props.ratesStore.browseRates : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1', width: this.props.modal ? '100%' : screenWidth-50, overflowX: 'scroll'}}>
          <div style={{minWidth: 1400}}>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={20} style={{textAlign: 'left'}}>
                    <Label.Group style={{margin: '0'}}>
                      <Label onClick={this.setColumn.bind(this, 'voice')} as='a' color={(column=='voice' && 'blue') || 'black'}><Icon name='microphone' /> Voice</Label>
                      <Label onClick={this.setColumn.bind(this, 'ivr')} as='a' color={(column=='ivr' && 'blue') || 'black'}><Icon name='volume down' /> IVR / Blast</Label>
                      <Label onClick={this.setColumn.bind(this, 'messaging')} as='a' color={(column=='messaging' && 'blue') || 'black'}><Icon name='comments outline' /> Messaging</Label>
                      <Label onClick={this.setColumn.bind(this, 'socmed')} as='a' color={(column=='socmed' && 'blue') || 'black'}><Icon name='share alternate' /> Socmed</Label>
                    </Label.Group>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell rowSpan={2}>#</Table.HeaderCell>
                  <Table.HeaderCell rowSpan={2}></Table.HeaderCell>
                  <Table.HeaderCell rowSpan={2} width={column=='socmed' ? 6 : 4}>
                    <Header as='h5' color='blue'>
                      CUSTOMER
                      <Header.Subheader>Customer info and packages</Header.Subheader>
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='4' colSpan={4}>
                    <Header as='h5' color='blue'>
                      LICENSE
                    </Header>
                  </Table.HeaderCell>
                  {column=='voice' &&<>
                  <Table.HeaderCell width='3' colSpan={3}>
                    <Header as='h5' color='blue'>
                      DIALING
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='5' colSpan={5}>
                    <Header as='h5' color='blue'>
                      VOICE
                    </Header>
                  </Table.HeaderCell></>}
                  {column=='ivr' &&<>
                  <Table.HeaderCell width='8' colSpan={4}>
                    <Header as='h5' color='blue'>
                      IVR BLAST
                    </Header>
                  </Table.HeaderCell></>}
                  {column=='messaging' &&<>
                  <Table.HeaderCell width='3' colSpan={3}>
                    <Header as='h5' color='blue'>
                      SMS
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='2' colSpan={2}>
                    <Header as='h5' color='blue'>
                      EMAIL
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='3' colSpan={3}>
                    <Header as='h5' color='blue'>
                      WHATSAPP
                    </Header>
                  </Table.HeaderCell></>}
                  {column=='socmed' &&<>
                  <Table.HeaderCell width='6' colSpan={3}>
                    <Header as='h5' color='blue'>
                      SOCIAL MEDIA (TWITTER. FACEBOOK, INSTAGRAM)
                    </Header>
                  </Table.HeaderCell></>}
                  {/* <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      IVR / BLAST
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      SMS
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      EMAIL
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      WHATSAPP
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      SOCIAL MEDIA
                    </Header>
                  </Table.HeaderCell> */}
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      User
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Omni
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Server
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      API
                    </Header>
                  </Table.HeaderCell>
                  {column=='voice' &&<>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Preview
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Progressive
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Predictive
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Omniphone
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Processing
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Recording
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Mailbox
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Delivery
                    </Header>
                  </Table.HeaderCell></>}
                  {column=='ivr' &&<>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      Processing
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      Recording
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      Mailbox
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      Delivery
                    </Header>
                  </Table.HeaderCell></>}
                  {column=='messaging' &&<>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Processing
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Number
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Masked
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Processing
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Delivery
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Processing
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Number
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      Masked
                    </Header>
                  </Table.HeaderCell></>}
                  {column=='socmed' &&<>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      Processing
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      Content Delivery
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='2'>
                    <Header as='h5' color='blue'>
                      Message Delivery
                    </Header>
                  </Table.HeaderCell></>}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={5} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
                { data!==undefined ? data.map((item,key)=>{
                    return(
                      <Table.Row key={++seq}>
                        <Table.Cell>{seq}</Table.Cell>
                        <Table.Cell textAlign='center'>
                          <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.ratesId)}/>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as='h5'>
                            {item.user && <Header.Content>
                              <b>{item.user.company || 'No Company Info !'}</b>
                              <Header.Subheader><b>{item.billType && item.billType.toUpperCase()}</b> / {item.packageId || 'No Package'} / {(item.user && (item.user.city))}</Header.Subheader>
                            </Header.Content>}
                            {(!item.user) && <Header.Content>
                              <b><i>{item.packageId || 'No Package'}</i></b>
                              <Header.Subheader><b>{item.billType && item.billType.toUpperCase()}</b> / Applied to all customers</Header.Subheader>
                            </Header.Content>}
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.userLicense).format('0,0')}
                              <Header.Subheader>/ user</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.omniLicense).format('0,0')}
                              <Header.Subheader>/ user</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.serverLicense).format('0,0')}
                              <Header.Subheader>/ server</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.apiLicense).format('0,0')}
                              <Header.Subheader>/ hit</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        {column=='voice' &&<>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.previewDialer).format('0,0')}
                              <Header.Subheader>/ call</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.progressiveDialer).format('0,0')}
                              <Header.Subheader>/ call</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.predictiveDialer).format('0,0')}
                              <Header.Subheader>/ call</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.omniphone).format('0,0')}
                              <Header.Subheader>/ call</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.voiceProcessing).format('0,0')}
                              <Header.Subheader>/ sec</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.voiceRecording).format('0,0')}
                              <Header.Subheader>/ sec</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.voiceMailboxDetection).format('0,0')}
                              <Header.Subheader>/ call</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.voiceDelivery).format('0,0')}
                              <Header.Subheader>/ sec</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell></>}
                        {column=='ivr' &&<>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.ivrProcessing).format('0,0')}
                              <Header.Subheader>/ sec</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.ivrRecording).format('0,0')}
                              <Header.Subheader>/ sec</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.ivrMailboxDetection).format('0,0')}
                              <Header.Subheader>/ call</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.voiceDelivery).format('0,0')}
                              <Header.Subheader>/ sec</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell></>}
                        {column=='messaging' &&<>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.smsProcessing).format('0,0')}
                              <Header.Subheader>/ mesg</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.smsNumberDelivery).format('0,0')}
                              <Header.Subheader>/ mesg</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.smsMaskedDelivery).format('0,0')}
                              <Header.Subheader>/ mesg</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.emailProcessing).format('0,0')}
                              <Header.Subheader>/ mesg</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.emailDelivery).format('0,0')}
                              <Header.Subheader>/ mesg</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.whatsappProcessing).format('0,0')}
                              <Header.Subheader>/ mesg</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.whatsappNumberDelivery).format('0,0')}
                              <Header.Subheader>/ mesg</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.whatsappMaskedDelivery).format('0,0')}
                              <Header.Subheader>/ mesg</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell></>}
                        {column=='socmed' &&<>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.socialProcessing).format('0,0')}
                              <Header.Subheader>/ mesg</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.socialContentDelivery).format('0,0')}
                              <Header.Subheader>/ mesg</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                          <Header as='h5'>
                            <Header.Content>
                              {numeral(item.socialMessageDelivery).format('0,0')}
                              <Header.Subheader>/ mesg</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell></>}
                      </Table.Row>
                    )
                  }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
                }
              </Table.Body>
            </Table>
          </div>
        </div>
        
        {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
               
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RatesBrowse)

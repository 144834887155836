import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Feed,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse, formatDistanceToNow } from "date-fns"

import { connect } from "react-redux"
import { recentContact } from "../../../actions/flow/omnichannelAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omnichannelStore: state.omnichannelStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    recentContact:(objParam) => dispatch(recentContact(objParam)),
  }
}

class InteractionRecent extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.omnichannelStore.recentContact!==undefined ? this.props.omnichannelStore.recentContact : {})
    this.props.recentContact(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.omnichannelStore.recentContact!==undefined ? this.props.omnichannelStore.recentContact : {})
    this.props.recentContact({search: {status: 'Responsed', ...search, engageDate: format(new Date(), 'yyyy-MM-dd')}, paging: paging})
  }

  render() {
    //console.log(this.props.omnichannelStore.recentContact)
    const { screenWidth, screenHeight, params }= this.props
    const { data, search, paging }= (this.props.omnichannelStore.recentContact!==undefined ? this.props.omnichannelStore.recentContact : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{flex: '1', padding: '0', minHeight: screenHeight/5}}>
          { data && data.targets && data.targets.length==0 && data.customers && data.customers.length==0 && data.visitors && data.visitors.length==0 && 
          <Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label>
          }
          <Card.Group>
            { data && data.targets && data.targets.length>0 && data.targets.map((item, i)=>{
                return(<Card as='a' key={i+300} onClick={this.props.setMode.bind(this, 1, item)} style={{background: '#f5f5f5'}}>
                  <Card.Content style={{padding: '1em'}}>
                    <Image
                      floated='left'
                      size='mini'
                      src={item.avatar==undefined || item.avatar==''? 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' : item.avatar} rounded size='mini' 
                    />
                    <Card.Header style={{fontSize: '1.15em', fontWeight: 'bold', color: '#2175ea'}}><b>{item.name || 'Guest'}</b> / Contacted</Card.Header>
                    <Card.Meta style={{fontSize: '.9em'}}>
                      <b>Campaign's Target</b>, {formatDistanceToNow(parse(item.recentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), {addSuffix: true,})}
                    </Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    <div style={{display: 'flex'}}>
                      <div style={{flex: '1'}}>
                        {item.channelTypes.includes('Voice') && <Card.Meta as='span'><Icon name='call square' /><b>Call</b></Card.Meta>}
                        {item.channelTypes.includes('Chat') && <Card.Meta as='span'><Icon name='comment alternate' /><b>Chat</b></Card.Meta>}
                        {item.channelTypes.includes('Email') && <Card.Meta as='span'><Icon name='envelope' /><b>Email</b></Card.Meta>}
                        {item.channelTypes.includes('Twitter') && <Card.Meta as='span'><Icon name='twitter' /><b>Twitter</b></Card.Meta>}
                        {item.channelTypes.includes('Facebook') && <Card.Meta as='span'><Icon name='facebook f' /><b>FB</b></Card.Meta>}
                        {item.channelTypes.includes('Instagram') && <Card.Meta as='span'><Icon name='instagram' /><b>IG</b></Card.Meta>}
                      </div>
                      <div>
                        <Card.Meta as='span'><Icon name='like' />{item.totConversation} Talks</Card.Meta>
                      </div>
                    </div>
                  </Card.Content>
                </Card>)
              })
            }
            { data && data.customers && data.customers.length>0 && data.customers.map((item, i)=>{
                return(<Card as='a' key={i+200} onClick={this.props.setMode.bind(this, 1, item)} style={{background: '#f5f5f5'}}>
                  <Card.Content style={{padding: '1em'}}>
                    <Image
                      floated='left'
                      size='mini'
                      src={item.avatar==undefined || item.avatar==''? 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' : item.avatar} rounded size='mini' 
                    />
                    <Card.Header style={{fontSize: '1.15em', fontWeight: 'bold', color: '#2175ea'}}><b>{item.name || 'Guest'}</b> / Contacted</Card.Header>
                    <Card.Meta style={{fontSize: '.9em'}}>
                      <b>Customer</b>, {formatDistanceToNow(
                        parse(item.recentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), {
                        addSuffix: true,
                      })}
                    </Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    <div style={{display: 'flex'}}>
                      <div style={{flex: '1'}}>
                        {item.channelTypes.includes('Voice') && <Card.Meta as='span'><Icon name='call square' /><b>Call</b></Card.Meta>}
                        {item.channelTypes.includes('Chat') && <Card.Meta as='span'><Icon name='comment alternate' /><b>Chat</b></Card.Meta>}
                        {item.channelTypes.includes('Email') && <Card.Meta as='span'><Icon name='envelope' /><b>Email</b></Card.Meta>}
                        {item.channelTypes.includes('Twitter') && <Card.Meta as='span'><Icon name='twitter' /><b>Twitter</b></Card.Meta>}
                        {item.channelTypes.includes('Facebook') && <Card.Meta as='span'><Icon name='facebook f' /><b>FB</b></Card.Meta>}
                        {item.channelTypes.includes('Instagram') && <Card.Meta as='span'><Icon name='instagram' /><b>IG</b></Card.Meta>}
                      </div>
                      <div>
                        <Card.Meta as='span'><Icon name='like' />{item.totConversation} Talks</Card.Meta>
                      </div>
                    </div>
                  </Card.Content>
                </Card>)
              })
            }
            { data && data.visitors && data.visitors.length>0 && data.visitors.map((item, i)=>{
                return(<Card as='a' key={i+100} onClick={this.props.setMode.bind(this, 1, item)} style={{background: '#f5f5f5'}}>
                  <Card.Content style={{padding: '1em'}}>
                    <Image
                      floated='left'
                      size='mini'
                      src={item.avatar==undefined || item.avatar==''? 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' : item.avatar} rounded size='mini' 
                    />
                    <Card.Header style={{fontSize: '1.15em', fontWeight: 'bold', color: '#2175ea'}}><b>{item.name || 'Guest'}</b> / Contacted</Card.Header>
                    <Card.Meta style={{fontSize: '.9em'}}>
                      <b>Visitor</b>, {formatDistanceToNow(
                        parse(item.recentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), {
                        addSuffix: true,
                      })}
                    </Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    <div style={{display: 'flex'}}>
                      <div style={{flex: '1'}}>
                        {item.channelTypes.includes('Voice') && <Card.Meta as='span'><Icon name='call square' /><b>Call</b></Card.Meta>}
                        {item.channelTypes.includes('Chat') && <Card.Meta as='span'><Icon name='comment alternate' /><b>Chat</b></Card.Meta>}
                        {item.channelTypes.includes('Email') && <Card.Meta as='span'><Icon name='envelope' /><b>Email</b></Card.Meta>}
                        {item.channelTypes.includes('Twitter') && <Card.Meta as='span'><Icon name='twitter' /><b>Twitter</b></Card.Meta>}
                        {item.channelTypes.includes('Facebook') && <Card.Meta as='span'><Icon name='facebook f' /><b>FB</b></Card.Meta>}
                        {item.channelTypes.includes('Instagram') && <Card.Meta as='span'><Icon name='instagram' /><b>IG</b></Card.Meta>}
                      </div>
                      <div>
                        <Card.Meta as='span'><Icon name='like' />{item.totConversation} Talks</Card.Meta>
                      </div>
                    </div>
                  </Card.Content>
                </Card>)
              })
            }
          </Card.Group>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InteractionRecent)

import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Feed, Divider, Label } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import MyBucketSearch from '../../libraries/agent/ticket/MyBucketSearch'

import MyBucketMenuBody from './MyBucketMenuBody';
import MyBucketPipelineBody from './MyBucketPipelineBody';

import MyTicketFormCreate from '../../libraries/agent/ticket/MyTicketFormCreate'
import MyTicketFormUpdate from '../../libraries/agent/ticket/MyTicketFormUpdate'


class MyBucketBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        layout: 1,
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        bucketId: 0,
      };
    }

    setLayout(v) {
      this.setState({layout: v})
    }

    setMode(mode, id, param) {
      this.setState({mode: mode, ticketId: id, param: param})
    }

    closeModal() {
      this.setState({
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        ticketId: 0,
      });
    }

    setBucket(v) {
      this.setState({
        bucket: v,
      })
    }
    setFilter(v, k) {
      this.setState({
        stamp: new Date().getTime(),
        filter: v,
        workspace: k,
      })
    }

    render() {
      const {mode, layout, ticketId, param, bucket, filter, workspace, stamp}= this.state
      const {screenWidth, screenHeight}= this.props
      
      // console.log(bucket, filter, workspace)
      return(
        <div>
          <MyBucketSearch filter={{...filter, scope: workspace}} bucket={bucket} mode={mode} layout={layout} setMode={this.setMode.bind(this)} setLayout={this.setLayout.bind(this)} screenWidth={screenWidth} />
          {layout==1 &&
            <MyBucketMenuBody stamp={stamp} bucket={bucket} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.setMode.bind(this)} setBucket={this.setBucket.bind(this)} setFilter={this.setFilter.bind(this)} openModal={this.props.openModal} closeModal={this.closeModal.bind(this)} />
          }
          {layout==2 && 
            <MyBucketPipelineBody stamp={stamp} bucket={bucket} filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.setMode.bind(this)} setBucket={this.setBucket.bind(this)} setFilter={this.setFilter.bind(this)} openModal={this.props.openModal} closeModal={this.closeModal.bind(this)} />
          }
        
          {/* {mode==2 && <MyTicketFormCreate mode={mode} ticketId={ticketId} param={param} setMode={this.setMode.bind(this)} />}
          {mode==3 && <MyTicketFormCreate mode={mode} ticketId={0} param={0} setMode={this.setMode.bind(this)} />} */}
          {/* {mode==2 && <ModalContainer size='fullscreen' content={<MyTicketFormCreate mode={mode} ticketId={ticketId} screenWidth={screenWidth} screenHeight={screenHeight-125} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />} */}
          {/* {(this.props.mode==3 || mode==3) && <ModalContainer size='fullscreen' content={<MyTicketFormCreate mode={mode} ticketId={0} screenWidth={screenWidth} screenHeight={screenHeight-125} param={0} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />} */}
          {mode==4 && <ModalContainer size='fullscreen' content={<MyTicketFormUpdate mode={mode} ticketId={ticketId} screenWidth={screenWidth} screenHeight={screenHeight-125} param={param} setMode={this.setMode.bind(this)} closeModal={this.closeModal.bind(this)} />} closeModal={this.closeModal.bind(this)} />}
        </div>
      )
    }
}

export default MyBucketBody

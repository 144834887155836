import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image } from 'semantic-ui-react'

export default class MenuLeftInapp extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      
    };
  }

  render() {
    const { screenWidth,  screenHeight, mini, module}= this.props
    
    if (mini) {
      return(
        <></>
      )
    } else {
      return (
        <div style={{top:'3em', position: 'fixed', height: screenHeight, width: '80px', zIndex: 99, background: '#dadadaaa'}}>
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '80px', padding: '3.5em 1.2em 0 0'}}>
            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Dashboard' content='Real time monitoring channel, traffic and system heartbeat.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'dashboard')}
                  color={this.props.mode=='dashboard' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='dashboard' ? '600' : '100'}}>
                    <Icon name='chart pie' style={{margin: '0', fontSize: '1.2em'}} color={this.props.mode=='dashboard' ? 'red' : 'grey'}/>
                </Header>
              } />
            </div>
            
            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Call Link' content='Share your link for audio or video call.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'call link')}
                  color={this.props.mode=='call link' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='call link' ? '600' : '100'}}>
                    <Icon name='at' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='call link' ? 'red' : 'grey'}/>
                </Header>
              } />
              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Conference' content='Setup conference for call ink container on certain schedule or kind of joining methods.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'conference')}
                  color={this.props.mode=='conference' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='conference' ? '600' : '100'}}>
                    <Icon name='list alternate outline' style={{margin: '0', fontSize: '1.2em'}} color={this.props.mode=='conference' ? 'red' : 'grey'}/>
                </Header>
              } />
            </div>
            
            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Call Tunnel' content='Available concurrent call tunnel for audio and video conference.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'call tunnel')}
                  color={this.props.mode=='call tunnel' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='call tunnel' ? '600' : '100'}}>
                    <Icon name='train' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='call tunnel' ? 'red' : 'grey'}/>
                </Header>
              } />
              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Active User' content='Monthly Active User which available for your usage licenses.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'active user')}
                  color={this.props.mode=='active user' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='active user' ? '600' : '100'}}>
                    <Icon name='users' style={{margin: '0', fontSize: '1.1em'}} color={this.props.mode=='active user' ? 'red' : 'grey'}/>
                </Header>
              } />
            </div>

            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='In-App Widget' content='Do your conversation through web or mobile app.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'widget')}
                  color={this.props.mode=='widget' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='widget' ? '600' : '100'}}>
                    <Icon name='tablet alternate' style={{margin: '0', fontSize: '1.27em'}} color={this.props.mode=='widget' ? 'red' : 'grey'}/>
                </Header>
              } />
              {/* <Divider style={{margin: '1em  0'}}/>
              <Popup header='Web Widget' content='Do your conversation through web with no other softphone required.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'web widget')}
                  color={this.props.mode=='web widget' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='web widget' ? '600' : '100'}}>
                    <Icon name='chrome' style={{margin: '0', fontSize: '1.27em'}} color={this.props.mode=='web widget' ? 'red' : 'grey'}/>
                </Header>
              } />
              <Divider style={{margin: '1em  0'}}/>
              <Popup header='IOS Widget' content='Single experience for customer interaction with no cost from IOS mobile app.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'IOS widget')}
                  color={this.props.mode=='IOS widget' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='IOS widget' ? '600' : '100'}}>
                    <Icon name='apple' style={{margin: '0', fontSize: '1.5em'}} color={this.props.mode=='IOS widget' ? 'red' : 'grey'}/>
                </Header>
              } />
              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Android Widget' content='Call from your android mobile app with no cost and experience gap.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'android widget')}
                  color={this.props.mode=='android widget' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='android widget' ? '600' : '100'}}>
                    <Icon name='android' style={{margin: '0', fontSize: '1.6em'}} color={this.props.mode=='android widget' ? 'red' : 'grey'}/>
                </Header>
              } /> */}
            </div>
          </div>
        </div>
      );
    }
  }
}

import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon, Table } from 'semantic-ui-react'

import { packageOptions, billingOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetPostpaid, getPostpaid,updatePostpaid, createPostpaid } from "../../actions/halo/postpaidAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    postpaidStore: state.postpaidStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPostpaid:(objParam) => dispatch(resetPostpaid(objParam)),
    getPostpaid:(objParam) => dispatch(getPostpaid(objParam)),
    createPostpaid:(objParam) => dispatch(createPostpaid(objParam)),
    updatePostpaid:(objParam) => dispatch(updatePostpaid(objParam)),
  }
}

class PostpaidForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      isLicense: false,
      isVoice: false,
      isSMS: false,
      isIVR: false,
      isEmail: false,
      isWhatsapp: false,
      isTwitter: false,
      isFacebook: false,
      isInstagram: false,
    }
  }

  componentDidMount(){
    this.props.resetPostpaid(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getPostpaid({postpaidId: this.props.postpaidId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.postpaidStore.updatePostpaid!=undefined ? this.props.postpaidStore.updatePostpaid : {}) : (this.props.postpaidStore.createPostpaid!=undefined ? this.props.postpaidStore.createPostpaid : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, postpaidId } = this.props
    mode==2 ? this.props.updatePostpaid({...values}) : this.props.createPostpaid({...values})
  }
  
  setExpand(v) {
    v=='license' && this.setState({isLicense: !this.state.isLicense,});
    v=='voice' && this.setState({isVoice: !this.state.isVoice,});
    v=='ivr' && this.setState({isIVR: !this.state.isIVR,});
    v=='sms' && this.setState({isSMS: !this.state.isSMS,});
    v=='email' && this.setState({isEmail: !this.state.isEmail,});
    v=='whatsapp' && this.setState({isWhatsapp: !this.state.isWhatsapp,});
    v=='twitter' && this.setState({isTwitter: !this.state.isTwitter,});
    v=='facebook' && this.setState({isFacebook: !this.state.isFacebook,});
    v=='instagram' && this.setState({isInstagram: !this.state.isInstagram,});
  }

  render() {
    const { isLicense, isVoice, isSMS, isIVR, isEmail, isWhatsapp, isTwitter, isFacebook, isInstagram}= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.postpaidStore.updatePostpaid!=undefined ? this.props.postpaidStore.updatePostpaid : {}) : (this.props.postpaidStore.createPostpaid!=undefined ? this.props.postpaidStore.createPostpaid : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='equal'>
              <Field name='period' component={InputField}
              label= 'Period'
              placeholder='Period'
              validate={[required]} />
              <Field name='billType' component={SelectField}
              options={billingOptions}
              label= 'Bill Type'
              placeholder='Bill Type'
              validate={[required]} />
              <Field name='billDate' component={DatePickerField}
              label= 'Bill Date'
              placeholder='Bill Date'
              validate={[required]} />
              <Field name='dueDate' component={DatePickerField}
              label= 'Due Date'
              placeholder='Due Date'
              validate={[required]} />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='billInfo' component={InputField}
              width={8}
              label='Bill Information'
              placeholder='Bill Information' />
              <Field name='billCode' component={InputField}
              width={4}
              label='Bill Code'
              placeholder='Bill Code' />
              <Field name='billSegment' component={InputField}
              width={4}
              label='Bill Segment'
              placeholder='Bill Segment' />
            </Form.Group>
          </Segment>
          
          <Segment attached='top'>
            <Header as='h5'>
              <Icon name='address card outline' style={{fontSize: '1em'}}/>
              <Header.Content>Customer / Auto generated reference to customer's table</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='packageId' component={SelectField}
              options={packageOptions}
              width={8}
              label='Package'
              placeholder='Package' />
              <Field name='company' component={InputField}
              width={8}
              label='Company'
              placeholder='Company' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='name' component={InputField}
              width={8}
              label='Contact'
              placeholder='Contact' />
              <Field name='mobile' component={InputField}
                            width={4}
              label='Mobile'
              placeholder='Mobile' />
              <Field name='email' component={InputField}
                            width={4}
              label='Email'
              placeholder='Email' />
            </Form.Group>
          </Segment>
          
          {/* <Segment attached='bottom'>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
                            label='Address'
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='city' component={InputField}
              width={8}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={4}
              placeholder='Province' />
              <Field name='country' component={InputField}
              width={4}
              placeholder='Country' />
            </Form.Group>
          </Segment> */}
          
          <Segment attached='top'>
            <Header as='h5'>
              <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
              <Header.Content>Billing Summary</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='billAmount' component={NumberField}
              readOnly
              width={4}
              label='Gross Amount' />
              <Field name='billDiscount' component={NumberField}
              readOnly
              width={4}
              label='Discount Amount' />
              <Field name='billVoucher' component={NumberField}
              readOnly
                            width={4}
              label='Voucher Discount'
              placeholder='Voucher Discount' />
              <Field name='billNettAmount' component={NumberField}
              readOnly
              width={4}
              label='Nett Amount' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='billLimit' component={NumberField}
              width={8}
              readOnly
              label='Bill Limit Amount' />
              <Field name='billVAT' component={NumberField}
              readOnly
              width={4}
              label='VAT Amount' />
              <Field name='dueAmount' component={NumberField}
              readOnly
                            width={4}
              label='Nett Amount + VAT'
              placeholder='Nett Amount + VAT' />
            </Form.Group>
          </Segment>
          <Segment attached='bottom'>
            <Table columns='16' selectable celled striped basic='very' style={{border: 'none'}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='7'>
                    <Header as='h5' color='blue'>
                      ITEM
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='3'>
                    <Header as='h5' color='blue'>
                      RATE
                      <Header.Subheader>Any changes apply to next bill item</Header.Subheader>
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='3'>
                    <Header as='h5' color='blue'>
                      QTY
                      <Header.Subheader>Volume of bill item</Header.Subheader>
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='3'>
                    <Header as='h5' color='blue'>
                      SUB TOTAL
                      <Header.Subheader>Cost of bill item</Header.Subheader>
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>A.</Table.Cell>
                  <Table.Cell colSpan={4}>
                    <Header as='h4' color='red'>
                      <Header.Content>License
                        <Header.Subheader>User as voice or omni License, Server License, API License</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name={(isLicense && 'toggle on') || 'toggle off'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.setExpand.bind(this, 'license')}/>
                  </Table.Cell>
                </Table.Row>
                {isLicense && <>
                <Table.Row>
                  <Table.Cell>
                    1.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        User License
                        <Header.Subheader>Voice basis user license</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateUserLicense' component={NumberField}
                                        suffix=' / User'
                    placeholder='Rate license' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyUserLicense' component={NumberField}
                                        suffix=' Users'
                    placeholder='Qty. of license' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costUserLicense' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost license' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    2.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Omni License
                        <Header.Subheader>Omni basis user license</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateOmniLicense' component={NumberField}
                                        suffix=' / Omni'
                    placeholder='Rate license' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyOmniLicense' component={NumberField}
                                        suffix=' Omnis'
                    placeholder='Qty. of license' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costOmniLicense' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost license' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    3.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Server License
                        <Header.Subheader>Server basis license</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateServerLicense' component={NumberField}
                                        suffix=' / Server'
                    placeholder='Rate license' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyServerLicense' component={NumberField}
                                        suffix=' Servers'
                    placeholder='Qty. of license' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costServerLicense' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost license' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    4.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        API License
                        <Header.Subheader>API hit basis license</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateApiLicense' component={NumberField}
                                        suffix=' / Hit'
                    placeholder='Rate license' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyApiLicense' component={NumberField}
                                        suffix=' Hits'
                    placeholder='Qty. of license' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costApiLicense' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost license' />
                  </Table.Cell>
                </Table.Row>
                </>}
                <Table.Row>
                  <Table.Cell>B.</Table.Cell>
                  <Table.Cell colSpan={4}>
                    <Header as='h4' color='red'>
                      <Header.Content>Voice
                        <Header.Subheader>Preview, Progressive, Predictive and Call Telephony</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name={(isVoice && 'toggle on') || 'toggle off'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.setExpand.bind(this, 'voice')}/>
                  </Table.Cell>
                </Table.Row>
                {isVoice && <>
                <Table.Row>
                  <Table.Cell>
                    1.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Preview Dialer
                        <Header.Subheader>Contacted target rates</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='ratePreviewDialer' component={NumberField}
                                        suffix=' / Contacted'
                    placeholder='Rate per contacted' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyPreviewDialer' component={NumberField}
                                        suffix=' Contacted'
                    placeholder='Qty. of contacted' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costPreviewDialer' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of contacted' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    2.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Progressive Dialer
                        <Header.Subheader>Contacted target rates</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateProgressiveDialer' component={NumberField}
                                        suffix=' / Contacted'
                    placeholder='Rate per contacted' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyProgressiveDialer' component={NumberField}
                                        suffix=' Contacted'
                    placeholder='Qty. of contacted' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costProgressiveDialer' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of contacted' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    3.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Predictive Dialer
                        <Header.Subheader>Contacted target rates</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='ratePredictiveDialer' component={NumberField}
                                        suffix=' / Contacted'
                    placeholder='Rate per contacted' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyPredictiveDialer' component={NumberField}
                                        suffix=' Contacted'
                    placeholder='Qty. of contacted' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costPredictiveDialer' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of contacted' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    4.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Voice Processing
                        <Header.Subheader>Second duration basis</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateVoiceProcessing' component={NumberField}
                                        suffix=' / Second'
                    placeholder='Rate per second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyVoiceProcessing' component={NumberField}
                                        suffix=' Seconds'
                    placeholder='Qty. of second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costVoiceProcessing' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of second' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    5.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Call Recording
                        <Header.Subheader>Second duration basis</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateVoiceRecording' component={NumberField}
                    decimalScale= {1}
                    suffix=' / Second'
                    placeholder='Rate per second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyVoiceRecording' component={NumberField}
                    suffix=' Seconds'
                    placeholder='Qty. of second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costVoiceRecording' component={NumberField}
                    prefix='Rp. '
                    placeholder='Cost of second' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    6.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Mailbox Detection
                        <Header.Subheader>Picked call basis</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateVoiceMailboxDetection' component={NumberField}
                                        suffix=' / Call'
                    placeholder='Rate per call' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyVoiceMailboxDetection' component={NumberField}
                                        suffix=' Calls'
                    placeholder='Qty. of call' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costVoiceMailboxDetection' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of calls' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    7.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Voice Delivery
                        <Header.Subheader>Second duration basis</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateVoiceDelivery' component={NumberField}
                                        suffix=' / Second'
                    placeholder='Rate per second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyVoiceDelivery' component={NumberField}
                                        suffix=' Seconds'
                    placeholder='Qty. of second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costVoiceDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of second' />
                  </Table.Cell>
                </Table.Row>
                </>}
                <Table.Row>
                  <Table.Cell>C.</Table.Cell>
                  <Table.Cell colSpan={4}>
                    <Header as='h4' color='red'>
                      <Header.Content>IVR
                        <Header.Subheader>Preview, Progressive, Predictive and Call Telephony</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name={(isIVR && 'toggle on') || 'toggle off'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.setExpand.bind(this, 'ivr')}/>
                  </Table.Cell>
                </Table.Row>
                {isIVR && <>
                <Table.Row>
                  <Table.Cell>
                    1.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        IVR Dialer
                        <Header.Subheader>Contacted target rates</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateIVRDialer' component={NumberField}
                                        suffix=' / Contacted'
                    placeholder='Rate per contacted' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyIVRDialer' component={NumberField}
                                        suffix=' Contacted'
                    placeholder='Qty. of contacted' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costIVRDialer' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of contacted' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    2.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Content Processing
                        <Header.Subheader>Second duration basis</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateIVRProcessing' component={NumberField}
                                        suffix=' / Second'
                    placeholder='Rate per second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyIVRProcessing' component={NumberField}
                                        suffix=' Seconds'
                    placeholder='Qty. of second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costIVRProcessing' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of second' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    3.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Call Recording
                        <Header.Subheader>Second duration basis</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateIVRRecording' component={NumberField}
                    decimalScale= {1}
                    suffix=' / Second'
                    placeholder='Rate per second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyIVRRecording' component={NumberField}
                    suffix=' Seconds'
                    placeholder='Qty. of second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costIVRRecording' component={NumberField}
                    prefix='Rp. '
                    placeholder='Cost of second' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    4.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Mailbox Detection
                        <Header.Subheader>Picked call by customer</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateIVRMailboxDetection' component={NumberField}
                                        suffix=' / Call'
                    placeholder='Rate per call' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyIVRMailboxDetection' component={NumberField}
                                        suffix=' Calls'
                    placeholder='Qty. of call' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costIVRMailboxDetection' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of calls' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    5.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        IVR Delivery
                        <Header.Subheader>Second duration basis</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateIVRDelivery' component={NumberField}
                                        suffix=' / Second'
                    placeholder='Rate per second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyIVRDelivery' component={NumberField}
                                        suffix=' Seconds'
                    placeholder='Qty. of second' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costIVRDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of second' />
                  </Table.Cell>
                </Table.Row>
                </>}
                <Table.Row>
                  <Table.Cell>D.</Table.Cell>
                  <Table.Cell colSpan={4}>
                    <Header as='h4' color='red'>
                      <Header.Content>SMS
                        <Header.Subheader>Preview, Progressive, Predictive and Call Telephony</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name={(isSMS && 'toggle on') || 'toggle off'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.setExpand.bind(this, 'sms')}/>
                  </Table.Cell>
                </Table.Row>
                {isSMS && <>
                <Table.Row>
                  <Table.Cell>
                    1.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Content Processing
                        <Header.Subheader>Message basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateSMSProcessing' component={NumberField}
                                        suffix=' / Mesg'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtySMSProcessing' component={NumberField}
                                        suffix=' Mesgs'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costSMSProcessing' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    2.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Number Sender
                        <Header.Subheader>Long number message basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateSMSNumberDelivery' component={NumberField}
                                        suffix=' / Mesg'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtySMSNumberDelivery' component={NumberField}
                                        suffix=' Mesgs'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costSMSNumberDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    3.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Masked Sender
                        <Header.Subheader>Masked sender message basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateSMSMaskedDelivery' component={NumberField}
                                        suffix=' / Mesg'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtySMSMaskedDelivery' component={NumberField}
                                        suffix=' Mesgs'
                    placeholder='Qty. of mesgs' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costSMSMaskedDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesgs' />
                  </Table.Cell>
                </Table.Row>
                </>}
                <Table.Row>
                  <Table.Cell>E.</Table.Cell>
                  <Table.Cell colSpan={4}>
                    <Header as='h4' color='red'>
                      <Header.Content>Email
                        <Header.Subheader>Preview, Progressive, Predictive and Call Telephony</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name={(isEmail && 'toggle on') || 'toggle off'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.setExpand.bind(this, 'email')}/>
                  </Table.Cell>
                </Table.Row>
                {isEmail && <>
                <Table.Row>
                  <Table.Cell>
                    1.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Content Processing
                        <Header.Subheader>Message basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateEmailProcessing' component={NumberField}
                                        suffix=' / Mesg'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyEmailProcessing' component={NumberField}
                                        suffix=' Mesgs'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costEmailProcessing' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    2.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Email Delivery
                        <Header.Subheader>Message basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateEmailDelivery' component={NumberField}
                                        suffix=' / Mesg'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyEmailDelivery' component={NumberField}
                                        suffix=' Mesgs'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costEmailDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                </>}
                <Table.Row>
                  <Table.Cell>F.</Table.Cell>
                  <Table.Cell colSpan={4}>
                    <Header as='h4' color='red'>
                      <Header.Content>Whatsapp
                        <Header.Subheader>Preview, Progressive, Predictive and Call Telephony</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name={(isWhatsapp && 'toggle on') || 'toggle off'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.setExpand.bind(this, 'whatsapp')}/>
                  </Table.Cell>
                </Table.Row>
                {isWhatsapp && <>
                <Table.Row>
                  <Table.Cell>
                    1.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Content Processing
                        <Header.Subheader>Message basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateWhatsappProcessing' component={NumberField}
                                        suffix=' / Mesg'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyWhatsappProcessing' component={NumberField}
                                        suffix=' Mesgs'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costWhatsappProcessing' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    2.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Number Sender
                        <Header.Subheader>Long number message basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateWhatsappNumberDelivery' component={NumberField}
                                        suffix=' / Mesg'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyWhatsappNumberDelivery' component={NumberField}
                                        suffix=' Mesgs'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costWhatsappNumberDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    3.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Masked Sender
                        <Header.Subheader>Masked sender message basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateWhatsappMaskedDelivery' component={NumberField}
                                        suffix=' / Mesg'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyWhatsappMaskedDelivery' component={NumberField}
                                        suffix=' Mesgs'
                    placeholder='Qty. of mesgs' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costWhatsappMaskedDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesgs' />
                  </Table.Cell>
                </Table.Row>
                </>}
                <Table.Row>
                  <Table.Cell>G.</Table.Cell>
                  <Table.Cell colSpan={4}>
                    <Header as='h4' color='red'>
                      <Header.Content>Twitter
                        <Header.Subheader>Preview, Progressive, Predictive and Call Telephony</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name={(isTwitter && 'toggle on') || 'toggle off'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.setExpand.bind(this, 'twitter')}/>
                  </Table.Cell>
                </Table.Row>
                {isTwitter && <>
                <Table.Row>
                  <Table.Cell>
                    1.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Content Processing
                        <Header.Subheader>Content basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateTwitterProcessing' component={NumberField}
                                        suffix=' / Content'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyTwitterProcessing' component={NumberField}
                                        suffix=' Contents'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costTwitterProcessing' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    2.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Post Content
                        <Header.Subheader>Content basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateTwitterContentDelivery' component={NumberField}
                                        suffix=' / Content'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyTwitterContentDelivery' component={NumberField}
                                        suffix=' Contents'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costTwitterContentDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    3.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Send Message
                        <Header.Subheader>Content basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateTwitterMessageDelivery' component={NumberField}
                                        suffix=' / Content'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyTwitterMessageDelivery' component={NumberField}
                                        suffix=' Contents'
                    placeholder='Qty. of mesgs' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costTwitterMessageDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesgs' />
                  </Table.Cell>
                </Table.Row>
                </>}
                <Table.Row>
                  <Table.Cell>H.</Table.Cell>
                  <Table.Cell colSpan={4}>
                    <Header as='h4' color='red'>
                      <Header.Content>Facebook
                        <Header.Subheader>Preview, Progressive, Predictive and Call Telephony</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name={(isFacebook && 'toggle on') || 'toggle off'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.setExpand.bind(this, 'facebook')}/>
                  </Table.Cell>
                </Table.Row>
                {isFacebook && <>
                <Table.Row>
                  <Table.Cell>
                    1.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Content Processing
                        <Header.Subheader>Content basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateFBProcessing' component={NumberField}
                                        suffix=' / Content'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyFBProcessing' component={NumberField}
                                        suffix=' Contents'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costFBProcessing' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    2.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Post Content
                        <Header.Subheader>Content basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateFBContentDelivery' component={NumberField}
                                        suffix=' / Content'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyFBContentDelivery' component={NumberField}
                                        suffix=' Contents'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costFBContentDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    3.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Send Message
                        <Header.Subheader>Content basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateFBMessageDelivery' component={NumberField}
                                        suffix=' / Content'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyFBMessageDelivery' component={NumberField}
                                        suffix=' Contents'
                    placeholder='Qty. of mesgs' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costFBMessageDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesgs' />
                  </Table.Cell>
                </Table.Row>
                </>}
                <Table.Row>
                  <Table.Cell>I.</Table.Cell>
                  <Table.Cell colSpan={4}>
                    <Header as='h4' color='red'>
                      <Header.Content>Instagram
                        <Header.Subheader>Preview, Progressive, Predictive and Call Telephony</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name={(isInstagram && 'toggle on') || 'toggle off'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.setExpand.bind(this, 'instagram')}/>
                  </Table.Cell>
                </Table.Row>
                {isInstagram && <>
                <Table.Row>
                  <Table.Cell>
                    1.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Content Processing
                        <Header.Subheader>Content basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateIGProcessing' component={NumberField}
                                        suffix=' / Content'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyIGProcessing' component={NumberField}
                                        suffix=' Contents'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costIGProcessing' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    2.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Post Content
                        <Header.Subheader>Content basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateIGContentDelivery' component={NumberField}
                                        suffix=' / Content'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyIGContentDelivery' component={NumberField}
                                        suffix=' Contents'
                    placeholder='Qty. of mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costIGContentDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesg' />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    3.
                  </Table.Cell>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        Send Message
                        <Header.Subheader>Content basis rate</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='rateIGMessageDelivery' component={NumberField}
                                        suffix=' / Content'
                    placeholder='Rate per mesg' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='qtyIGMessageDelivery' component={NumberField}
                                        suffix=' Contents'
                    placeholder='Qty. of mesgs' />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name='costIGMessageDelivery' component={NumberField}
                                        prefix='Rp. '
                    placeholder='Cost of mesgs' />
                  </Table.Cell>
                </Table.Row>
                </>}
              </Table.Body>
            </Table>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Postpaid' : 'Insert Postpaid') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.postpaidStore.getPostpaid!=undefined && state.postpaidStore.getPostpaid.progress==1 ? state.postpaidStore.getPostpaid.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'PostpaidForm',	// a unique identifier for this form
  })(PostpaidForm)
  )
)
import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import { scopeOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, LabelInputField, InputField, CheckboxField, SelectField, DatePickerField, TextAreaField, NumberField, WysiwygField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetDocument, getDocument,updateDocument, createDocument } from "../../actions/halo/documentAction"

//CONNECT REDUX STORE
const selector = formValueSelector('DocumentForm')

const mapStateToProps = (state, props) => {
  return {
    documentStore: state.documentStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetDocument:(objParam) => dispatch(resetDocument(objParam)),
    getDocument:(objParam) => dispatch(getDocument(objParam)),
    createDocument:(objParam) => dispatch(createDocument(objParam)),
    updateDocument:(objParam) => dispatch(updateDocument(objParam)),
  }
}

class DocumentForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  componentDidMount(){
    this.props.resetDocument(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getDocument({documentId: this.props.documentId})
    }
  }

  componentDidUpdate() {
    const { mode, modal } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.documentStore.updateDocument!=undefined ? this.props.documentStore.updateDocument : {}) : (this.props.documentStore.createDocument!=undefined ? this.props.documentStore.createDocument : {}))
    
    if (!modal && progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
    if (modal && progress==1 && code=='00') {
      this.props.closeModal('hide');
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    
    mode==2 ? this.props.updateDocument({...values}) : this.props.createDocument({...values, ...{clientId: this.props.clientId}})
  }

  contentItems(qty) {
    var contentItems= this.props.formValues!=undefined && this.props.formValues.contentItems!=undefined ? this.props.formValues.contentItems : []
    var items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: contentItems[i]!=undefined ? contentItems[i].item : null,
        price: contentItems[i]!=undefined ? contentItems[i].price : null,
        discount: contentItems[i]!=undefined ? contentItems[i].discount : null,
        subtotal: contentItems[i]!=undefined ? contentItems[i].subtotal : null,
      };
    }

    return (
      <>
        {
          items.map((item, index)=>{
            return(
              <Table.Row key={index}>
                <Table.Cell style={{background: index%2==0 ? '#fe2' : '#fafaf5'}}><h3>{(index+1)}</h3></Table.Cell>
                <Table.Cell>
                  <Form.Group>
                    <Field name={'headline_'+ (index+1)} component={InputField}
                    width={8}
                    label='Headline'
                    validate={[required]}
                    placeholder='Headline' />
                    <Field name={'keyword_'+ (index+1)} component={InputField}
                    width={8}
                    label='Keywords / Searching'
                    placeholder='Keywords for searching / filtering' />
                  </Form.Group>
                  <Form.Group>
                    <Field name={'content_'+ (index+1)} component={WysiwygField}
                    mode='wysiwyg'
                    width={16}
                    rows={6}
                    validate={[required]}
                    label='Content'
                    placeholder='Content' />
                  </Form.Group>

                  <Divider />
                  <Form.Group>
                    <Field name={'image_'+ (index+1)} component={ImageUploadField}
                    width={800} 
                    height={300} 
                    aspectRation={3/8} 
                    />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
            )
          })
        }
      </>
    )
  }

  incrContentQty(isIncrease) {
    // console.log('BILL QTY', this.props.contentQty)

    if (isIncrease) {
      // console.log('BILL QTY++', (this.props.contentQty+1))
      if (this.props.contentQty<10) {
        this.props.dispatch(change('DocumentForm', 'contentQty', ((isNaN(this.props.contentQty) ? 0 : parseInt(this.props.contentQty)) +1)))
      }
    } else {
      // console.log('BILL QTY--', (this.props.contentQty-1))
      if (this.props.contentQty>1) {
        this.props.dispatch(change('DocumentForm', 'contentQty', ((isNaN(this.props.contentQty) ? 0 : parseInt(this.props.contentQty)) -1)))
      }
    }
  }

  render() {
    const {  }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, modal } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.documentStore.updateDocument!=undefined ? this.props.documentStore.updateDocument : {}) : (this.props.documentStore.createDocument!=undefined ? this.props.documentStore.createDocument : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          
          <Segment>
            <Form.Group widths='equal' >
              <Form.Field>
                <Field name='image' component={ImageUploadField}
                width={800} 
                height={300} 
                aspectRation={3/8} 
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths='16' style={{marginTop: '2em'}}>
              <Field name='isEnabled' component={CheckboxField}
              toggle
              width={12}
              caption='Enabled'
              label='Enable Now !'
              placeholder='Status enable/disabled' />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
            </Form.Group>
            
            <Form.Group widths='16'>
              <Field name='order' component={NumberField}
              width={1}
              validate={[required]}
              label='Order'
              placeholder='Order' />
              <Field name='title' component={InputField}
              width={11}
              validate={[required]}
              label='Title / Chapter'
              placeholder='Title / Chapter' />
              <Field name='scope' component={SelectField}
              width={4}
              options= {scopeOptions()}
              validate={[required]}
              label='Scope'
              placeholder='Scope' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='content' component={TextAreaField}
              width={16}
              rows={6}
              label='Content'
              placeholder='Content' />
            </Form.Group>

            <Header color='blue' as='h5'>
              <Icon name='envelope open outline' style={{fontSize: '1em'}}/>
              <Header.Content>Metadata</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='keywords' component={InputField}
                            label='Keywords'
              placeholder='Keywords' />
              <Field name='descript' component={InputField}
                            label='Description'
              placeholder='Description' />
            </Form.Group>
          </Segment>
          <Segment>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h5' style={{margin: '0'}}>
                  <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
                  <Header.Content>Sub Contents</Header.Content>
                </Header>
              </div>
              <div>
                <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrContentQty.bind(this,false)}/>
              </div>
              <div>
                  <Field name='contentQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}}
                  type='number'
                                    />
              </div>
              <div>
                <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrContentQty.bind(this,true)}/>
              </div>
            </div>
            <Table compact celled definition striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='16'>
                    <Header as='h5' color='blue'>
                      SUB CONTENTS
                    </Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.contentItems(this.props.contentQty||0)
                }
              </Table.Body>
            </Table>
          </Segment>
          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Document' : 'Insert Document') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.documentStore.getDocument!=undefined && state.documentStore.getDocument.progress==1 ? {...{isEnabled: 1, contentQty: '0'}, ...state.documentStore.getDocument.data} : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
      contentQty: selector(state, 'contentQty'),
    }),
  )
  (reduxForm({
    form: 'DocumentForm',	// a unique identifier for this form
  })(DocumentForm)
  )
)

import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import { browsePrepaid } from "../../actions/halo/prepaidAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    prepaidStore: state.prepaidStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browsePrepaid:(objParam) => dispatch(browsePrepaid(objParam)),
  }
};


class PrepaidBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.prepaidStore.browsePrepaid!==undefined ? this.props.prepaidStore.browsePrepaid : {})
    this.props.browsePrepaid(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.prepaidStore.browsePrepaid!==undefined ? this.props.prepaidStore.browsePrepaid : {})
    this.props.browsePrepaid({search: {...search}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.prepaidStore.browsePrepaid!==undefined ? this.props.prepaidStore.browsePrepaid : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                {/* <Table.HeaderCell></Table.HeaderCell> */}
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>
                    CUSTOMER
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2' style={{background: '#fff2f2'}}>
                  <Header as='h5' color='blue'>
                    DEPOSIT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>
                    SUB DEPOSIT
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>
                    GRACED
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>
                    EXPIRED
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={6} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      {/* <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.prepaidId)}/>
                      </Table.Cell> */}
                      <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='credit card outline' onClick={this.props.openModal.bind(this, 'topup', item)} />
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.user && item.user.company}
                            {/* <Header.Subheader>{item.user && (item.user.address +', '+ item.user.city)}</Header.Subheader> */}
                            <Header.Subheader>{item.user && ('E: '+ item.user.email +', P: '+ item.user.mobile)}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell textAlign='right' style={{background: '#fff2f2'}}>
                        <Header as='h5' style={{fontWeight: 'bold'}}>
                          <Header.Content>
                            {numeral(item.depositCredit).format('0,0')}
                            <Header.Subheader>Available Credit</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <div style={{display: 'flex', textAlign: 'center'}}>
                          <Header as='h5' style={{margin: '0 0 .5em 0', flex: '1'}}>
                            <Header.Content>
                              {numeral(item.depositOmni).format('0.0a')}
                              <Header.Subheader>Omni</Header.Subheader>
                            </Header.Content>
                          </Header>
                          <Header as='h5' style={{margin: '0 0 .5em 0', flex: '1'}}>
                            <Header.Content>
                              {numeral(item.depositTelco).format('0.0a')}
                              <Header.Subheader>Telco</Header.Subheader>
                            </Header.Content>
                          </Header>
                          <Header as='h5' style={{margin: '0 0 .5em 0', flex: '1'}}>
                            <Header.Content>
                              {numeral(item.depositEmail).format('0.0a')}
                              <Header.Subheader>Email</Header.Subheader>
                            </Header.Content>
                          </Header>
                          <Header as='h5' style={{margin: '0 0 .5em 0', flex: '1'}}>
                            <Header.Content>
                              {numeral(item.depositWhatsapp).format('0.0a')}
                              <Header.Subheader>Whatsapp</Header.Subheader>
                            </Header.Content>
                          </Header>
                          <Header as='h5' style={{margin: '0 0 .5em 0', flex: '1'}}>
                            <Header.Content>
                              {numeral(item.depositSocmed).format('0.0a')}
                              <Header.Subheader>Socmed</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{margin: '0 0 .5em 0', flex: '1'}}>
                          <Header.Content>
                            Graced Period
                            <Header.Subheader>{item.gracedStamp}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{margin: '0 0 .5em 0', flex: '1'}}>
                          <Header.Content>
                            Exp. Period
                            <Header.Subheader>{item.expiredStamp}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
               
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrepaidBrowse)

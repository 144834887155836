import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'


import MenuHorizontal from '../../../libraries/common/MenuHorizontal';

import ReportingBody from '../halo/ReportingBody'
import WallboardBody from '../halo/WallboardBody'
import MonitoringBody from './MonitoringBody';

const options= (module)=>{
  return([
    {
      key: '1',
      icon: 'desktop',
      caption: 'Monitoring',
      hint:{
        header: 'Agent Monitoring',
        content: 'Real time agent performance and activities, show them you are watching all the times.'
      }
    },
    {
      key: '2',
      icon: 'chart line',
      caption: 'Wallboard',
      hint:{
        header: 'Wallboard Display',
        content: 'Show your platform real time status on big screen.'
      }
    },
    {
      key: '3',
      icon: 'file alternate outline',
      caption: 'Reporting',
      hint:{
        header: 'Transaction Reporting',
        content: 'Download your reporting or manualy generate if necessary.'
      }
    },
  ])
}

class DashboardBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, dashboardId: id})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <div>
          <div style={{margin: '0em 1em 0 1em'}}>
            <MenuHorizontal screenWidth={screenWidth} onClick={this.setMode.bind(this)} options= {options(module)} color='red'/>
          </div>
          <div>
            {mode==1 && <MonitoringBody screenHeight={screenHeight} screenWidth={screenWidth} />}
            {mode==2 && <WallboardBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)}  />}
            {mode==3 && <ReportingBody screenHeight={screenHeight} screenWidth={screenWidth} openWindow={this.props.openWindow.bind(this)} openModal={this.props.openModal.bind(this)} closeModal={this.props.closeModal.bind(this)}  />}
          </div>
        </div>
      )
    }
}

export default DashboardBody

import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { yesnoOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, TextAreaField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'
import { connect } from "react-redux"
import { resetWhitelist, getWhitelist,updateWhitelist, createWhitelist } from "../../actions/halo/whitelistAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    whitelistStore: state.whitelistStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetWhitelist:(objParam) => dispatch(resetWhitelist(objParam)),
    getWhitelist:(objParam) => dispatch(getWhitelist(objParam)),
    createWhitelist:(objParam) => dispatch(createWhitelist(objParam)),
    updateWhitelist:(objParam) => dispatch(updateWhitelist(objParam)),
  }
}

class WhitelistForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetWhitelist(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getWhitelist({whitelistId: this.props.whitelistId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.whitelistStore.updateWhitelist!=undefined ? this.props.whitelistStore.updateWhitelist : {}) : (this.props.whitelistStore.createWhitelist!=undefined ? this.props.whitelistStore.createWhitelist : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, whitelistId } = this.props
    mode==2 ? this.props.updateWhitelist({...values}) : this.props.createWhitelist({...values})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.whitelistStore.updateWhitelist!=undefined ? this.props.whitelistStore.updateWhitelist : {}) : (this.props.whitelistStore.createWhitelist!=undefined ? this.props.whitelistStore.createWhitelist : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Form.Group widths='16'>
              <Field name='network' component={InputField}
                            width={4}
              label='Network'
              placeholder='Source network' />
              <Field name='isApplied' component={SelectField}
              width={2}
              options={yesnoOptions()}
              label='Is Applied'
              placeholder='Is Applied' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='descript' component={TextAreaField}
              width={4}
                            rows={10}
              label='Description'
              placeholder='Description' />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Whitelist' : 'Insert Whitelist') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.whitelistStore.getWhitelist!=undefined && state.whitelistStore.getWhitelist.progress==1 ? state.whitelistStore.getWhitelist.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'WhitelistForm',	// a unique identifier for this form
  })(WhitelistForm)
  )
)
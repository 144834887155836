import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';

import Omniphone from '../../libraries/omniphone/Omniphone';
import NotificationWidget from '../../libraries/widget/NotificationWidget'
// import MessagingWidget from '../../libraries/agent/MessagingWidget'
import IdleWidget from '../../libraries/widget/IdleWidget'
// import AppointmentWidget from '../../libraries/widget/AppointmentWidget'
import ConversationWidget from '../../libraries/widget/ConversationWidget'
import AssignmentWidget from '../../libraries/widget/AssignmentWidget';
import SessionWidget from '../../libraries/widget/SessionWidget';
// import CredentialWidget from '../../libraries/widget/CredentialWidget';
// import OmniphoneVideo from '../../libraries/omniphone/OmniphoneVideo';

//OUTBOUND
import StatisticBody from './StatisticBody'
import LoggerBody from './LoggerBody'

import AppointmentBody from './AppointmentBody'
import TargetBody from './TargetBody'
import RecordingBody from './RecordingBody';
import ConversationBody from './ConversationBody';
import DialerBody from './DialerBody';

//INBOUND
import OmniStatisticBody from './inbound/StatisticBody'
import InteractionBody from './InteractionBody';
import InteractionHistoricalBody from './InteractionHistoricalBody';

//TICKET ADD-ON MODULE
import MyBucketBody from './MyBucketBody';
import KnowledgeBody from './KnowledgeBody';

// import PerformanceBody from './PerformanceBody'
// import ConversationBody from './ConversationBody'
// import TransactBody from './TransactBody'
// import CollectionBody from './CollectionBody'
// import RestructuringBody from './RestructuringBody'
// import TelesaleBody from './TelesaleBody'
// import VerificationBody from './VerificationBody'

//SYSTEM
import MenuLeft from '../../libraries/agent/MenuLeft'

import OnboardingBody from './OnboardingBody';
import TopupBody from './TopupBody';

import ProfileBody from './ProfileBody'
import MessageBody from './MessageBody'
import SettingBody from './SettingBody'

import { connect } from "react-redux"
import { accountOmniphone, contactOmniphone } from "../../actions/stream/omniphoneAction"
import { getSetting } from "../../actions/halo/settingAction"
import { checkToken } from "../../actions/auth/authAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    omniphoneStore: state.omniphoneStore,
    authStore: state.authStore,
    settingStore: state.settingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    accountOmniphone:(objParam) => dispatch(accountOmniphone(objParam)),
    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),

    checkToken:(objParam) => dispatch(checkToken(objParam)),

    getSetting:(objParam) => dispatch(getSetting(objParam)),
  }
}

class AgentBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      onboarding: false,
      topupalert: false,

      sessionModal: true,
      wide: false,
    }
  }

  componentDidMount() {
    this.props.accountOmniphone();
    this.props.checkToken();
    this.props.getSetting();
    
    navigator.mediaDevices.getUserMedia({ audio: true, video: false });
  }

  componentDidUpdate() {
    const { code, data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}
    data && code=='00' && data.isConfigured=='0' && this.state.onboarding==false &&this.setState({
      onboarding: true,
    });

    data && code=='00' && data.billingType=='prepaid' && parseFloat(data.depositCredit)<=0 && this.state.topupalert==false && this.setState({
      topupalert: true,
    })
  }

  setWide() {
    this.setState({
      wide: !this.state.wide,
    })
  }

  render() {
    const { onboarding, topupalert, wide }=this.state
    const { mode, modal, screenWidth, screenHeight, mini, module, params }= this.props

    const { data }= (this.props.omniphoneStore.accountOmniphone!==undefined ? this.props.omniphoneStore.accountOmniphone : {})

    return (
      <div>
        <div style={{marginTop: '3em'}}>
          <div style={{display: 'flex'}}>
            <div style={{background: '#ccc', minHeight: screenHeight-300, borderRight: '1px solid #fff'}}>
              <MenuLeft openModal={this.props.openModal} openWindow={this.props.openWindow} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} mini={mini}/>
            </div>
            <div style={{marginTop: '.5em', flex: '1', marginLeft: mini ? '2px' : '80px',  padding: '.5em .5em', background: '#fafafa', minHeight: screenHeight}}>
              <div style={{zIndex: 999, position: 'fixed'}}>
                <Button size='small' color='black' icon onClick={this.setWide.bind(this)}><Icon name={this.state.wide ? 'arrows alternate vertical' : 'arrows alternate horizontal'} /></Button>
              </div>
              {module=='outbound' && <div>
                <div style={{padding: '1em', marginTop: '.5em'}}>
                  <SessionWidget screenHeight={screenHeight} screenWidth={screenWidth} />
                </div>
                {mode==='statistic' && <div style={{padding: '1em', marginTop: '-1.5em'}}>
                  <AssignmentWidget openWindow={this.props.openWindow} screenHeight={screenHeight} screenWidth={screenWidth} />
                </div>}
                <div>
                  {mode==='statistic' && <StatisticBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />}
                  {/* {mode==='performance' && <PerformanceBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />} */}
                  {mode==='target' && <TargetBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />}
                  {mode==='logger' && <LoggerBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />}
                  
                  {mode==='appointment' && <AppointmentBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />}
                  
                  {mode==='interaction' && <ConversationBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} openModal={this.props.openModal} />}
                  {/* {mode==='transact' && <TransactBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />} */}

                  {mode==='knowledge' && <KnowledgeBody module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
                </div>
              </div>}
              {module=='inbound' && <div>
                <div style={{padding: '1em', marginTop: '.5em'}}>
                  <SessionWidget screenHeight={screenHeight} screenWidth={screenWidth} />
                </div>
                {mode==='statistic' && <div style={{padding: '1em', marginTop: '-1.5em'}}>
                  <AssignmentWidget openWindow={this.props.openWindow} screenHeight={screenHeight} screenWidth={screenWidth} />
                </div>}
                <div>
                  {mode==='statistic' && <OmniStatisticBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />}
                  {/* {mode==='performance' && <PerformanceBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />} */}
                  {mode==='target' && <TargetBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />}
                  {mode==='logger' && <LoggerBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />}
                  
                  {mode==='appointment' && <AppointmentBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />}
                  
                  {mode==='interaction' && <InteractionBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} openModal={this.props.openModal} />}

                  {/* {mode==='conversation' && <ConversationBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} openModal={this.props.openModal} />} */}
                  {/* {mode==='transact' && <TransactBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} />} */}

                  {mode==='ticket' && <MyBucketBody module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}

                  {mode==='knowledge' && <KnowledgeBody module={module} screenHeight={screenHeight} screenWidth={screenWidth} openModule={this.props.openModule} openWindow={this.props.openWindow} mode={mode} openModal={this.props.openModal} closeModal={this.props.closeModal} modal={modal}  params={params} />}
                </div>
              </div>}
              
              {modal==='profile' && <ModalContainer size='tiny' content={<ProfileBody screenWidth={screenWidth} />} closeModal={this.props.closeModal} /> }
              {modal==='message' && <ModalContainer size='tiny' content={<MessageBody screenWidth={screenWidth} />} closeModal={this.props.closeModal} /> }
              {mode==='setting' && <ModalContainer size='large' content={<SettingBody screenWidth={screenWidth} />} closeModal={this.props.closeModal} /> }

              {modal==='dialer' && <ModalContainer size='tiny' content={<DialerBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal}  params={this.props.params} />} closeModal={this.props.closeModal} /> }
              {modal==='recording' && <ModalContainer size='small' content={<RecordingBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal}  params={this.props.params} />} closeModal={this.props.closeModal} /> }
              
              {modal && modal.substring(0,10)==='historical' && <ModalContainer size='large' content={<InteractionHistoricalBody channel={modal.substring(11)} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.props.openModal} closeModal={this.props.closeModal}  params={this.props.params} />} closeModal={this.props.closeModal} /> }

              {onboarding && <OnboardingBody dialog />}
              {!onboarding && topupalert && <ModalContainer locked size='small' content={<TopupBody screenWidth={screenWidth} />} closeModal={this.props.closeModal} /> }

            </div>
            <div style={{paddingTop: '2.5em'}}>
              <div style={wide==1 ? {padding: 0, border: 'none'} : {background: '#f5f1ed', padding: '255px 1.5em 4em 1.5em', border: '1px dashed rgb(255, 167, 108)', borderRadius: '.3em 0 0 .3em'}}>
                <div style={{position: 'fixed', zIndex: 999, background: '#f0f0f0aa', padding: '1.5em', borderRadius: '1em', top: '5.6em', right: '0'}}>
                  <div style={{width: '255px', height: '210px'}}>
                    {data!==undefined && data.voipAccount!==undefined &&
                      // <Omniphone
                      // mode='mini'
                      // sipAccount='7000000001' 
                      // sipPasswd='111111'
                      // sipHost='call.halopagi.com:4063' 
                      // sipRealm='halopagi'/>
                      <Omniphone
                      mode='mini'
                      channel= {data.channel || 'Audio'}
                      agent={{name: data.name, avatar: data.avatar, initial: data.name.split(' ')[0][0]+ (data.name.split(' ')[1] ? data.name.split(' ')[1][0] : '')}}
                      sipAccount={data.voipAccount}
                      sipPasswd={data.voipPasswd}
                      sipHost={data.voipHost +':'+ data.voipPort}
                      sipRealm={data.voipRealm}
                      screenHeight= {screenHeight}
                      />
                    }
                  </div>
                </div>
                {/* <MessagingWidget /> */}
                {/* <OmniphoneWidget /> */}
                {/* {(mode==='statistic' || mode==='target') && <AppointmentWidget />} */}
                {/* <div style={{marginTop: '2em'}}>
                  {this.props.mode=='verification' && <VerificationWidget />}
                  {this.props.mode=='appointment' && <AppointmentWidget />}
                </div> */}

                {!wide && <div style={{width: '255px', }}>
                  <IdleWidget />
                  <ConversationWidget mini={true} />
                  <Divider />
                  <NotificationWidget />
                </div>}
              </div>
            </div>
          </div>
        </div>

        {/* <div id='omniphoneVideo' style={{display: 'none', position: 'absolute', left: 38, bottom: 30, zIndex: 9999, background: '#ff0'}}>
          <OmniphoneVideo />
        </div> */}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentBody)
import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  Script,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { connect } from "react-redux"
import { browseScript } from "../../../actions/halo/scriptAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    scriptStore: state.scriptStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseScript:(objParam) => dispatch(browseScript(objParam)),
  }
};


class ScriptBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  componentWillMount() {
    this.props.browseScript()
  }

  render() {
    const { screenWidth }= this.props
    const data= (this.props.scriptStore.browseScript!==undefined ? this.props.scriptStore.browseScript.data : undefined)
    let seq= 0
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Card.Group itemsPerRow={3} stackable doubling>
          { data!=undefined && data.length==0 &&<Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label>}
          { data!=undefined ? data.map(item=>{
          return(
          <Card key={seq++} as='a' onClick={this.props.setMode.bind(this,2, item.scriptId)}>
            <Card.Content extra style={{textAlign: 'center', padding: '2em 1em'}}>
              <Header as='h3' color='grey' icon inverted={item.isEnabled=='1' ? false : true}>
                {item.media=='Voice' && <Icon name='phone' style={{fontSize: '2em'}}/>}
                {item.media=='IVR' && <Icon name='volume down' style={{fontSize: '2em'}}/>}
                {item.media=='SMS' && <Icon name='sticky note outline' style={{fontSize: '2em'}}/>}
                {item.media=='Email' && <Icon name='envelope outline' style={{fontSize: '2em'}}/>}
                {item.media=='Whatsapp' && <Icon name='whatsapp' style={{fontSize: '2em'}}/>}
                {item.media=='Telegram' && <Icon name='telegram' style={{fontSize: '2em'}}/>}
                {item.media=='Instagram' && <Icon name='instagram' style={{fontSize: '2em'}}/>}
                {item.media=='Facebook' && <Icon name='facebook' style={{fontSize: '2em'}}/>}
                {item.media=='Twitter' && <Icon name='twitter' style={{fontSize: '2em'}}/>}
                <Divider />
                {item.media}
              </Header>
            </Card.Content>
            <Card.Content style={{textAlign: 'center'}}>
              <Header as='h4' style={item.isEnabled!='1'? {textDecoration: 'line-through'} : {}} color={(item.isEnabled=='0' && 'brown') || 'black'}>
                {item.name}
                <Header.Subheader>{(item.isEnabled=='0' && <i> (Disabled)</i>)}</Header.Subheader>
              </Header>
            </Card.Content>
            <Card.Content extra style={{textAlign: 'center'}}>
              <Header as='h5' color='grey'>{item.client || 'Nobody'}</Header>
            </Card.Content>
          </Card>
          )}) : <div style={{padding: '1em'}}><Icon name='circle notch' loading /></div>
          }
        </Card.Group>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScriptBrowse)

import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import {groupOptions, yesnoOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, LabelField, CheckboxField, SelectField, DatePickerField, NumberField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetBilling, getBilling,updateBilling, createBilling } from "../../actions/halo/billingAction"

//CONNECT REDUX STORE
const selector = formValueSelector('BillingForm')

const mapStateToProps = (state, props) => {
  return {
    billingStore: state.billingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetBilling:(objParam) => dispatch(resetBilling(objParam)),
    getBilling:(objParam) => dispatch(getBilling(objParam)),
    createBilling:(objParam) => dispatch(createBilling(objParam)),
    updateBilling:(objParam) => dispatch(updateBilling(objParam)),
  }
}

class BillingForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      showPasswd: false,
    }
  }

  componentDidMount(){
    this.props.resetBilling(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getBilling({billingId: this.props.billingId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.billingStore.updateBilling!=undefined ? this.props.billingStore.updateBilling : {}) : (this.props.billingStore.createBilling!=undefined ? this.props.billingStore.createBilling : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    mode==2 ? this.props.updateBilling({...values}) : this.props.createBilling({...values})
  }

  billItems(qty) {
    var billItems= this.props.formValues!=undefined && this.props.formValues.billItems!=undefined ? this.props.formValues.billItems : []
    var items= [];
    for (var i=0; i<qty; i++) {
      items[i]= {
        item: billItems[i]!=undefined ? billItems[i].item : null,
        price: billItems[i]!=undefined ? billItems[i].price : null,
        discount: billItems[i]!=undefined ? billItems[i].discount : null,
        subtotal: billItems[i]!=undefined ? billItems[i].subtotal : null,
      };
    }

    return (
      <>
        {
          items.map((item, index)=>{
            return(
              <Table.Row key={index}>
                <Table.Cell>{(index+1)}.</Table.Cell>
                <Table.Cell>
                  <Form.Group widths='equal'>
                    <Field name={'item_'+ (index+1)} component={InputField}
                                        placeholder='Billed Item' />
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Form.Group widths='equal'>
                    <Field name={'itemPrice_'+ (index+1)} component={InputField}
                                        placeholder='Price' />
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Form.Group widths='equal'>
                    <Field name={'itemDiscount_'+ (index+1)} component={InputField}
                                        placeholder='Discount' />
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Form.Group widths='equal'>
                    <Field name={'itemQty_'+ (index+1)} component={InputField}
                                        placeholder='Qty' />
                  </Form.Group>
                </Table.Cell>
                <Table.Cell>
                  <Form.Group widths='equal'>
                    <Field name={'itemSubtotal_'+ (index+1)} component={InputField}
                                        placeholder='Subtotal' />
                  </Form.Group>
                </Table.Cell>
              </Table.Row>
            )
          })
        }
      </>
    )
  }

  incrBillQty(isIncrease) {
    // console.log('BILL QTY', this.props.billQty)

    if (isIncrease) {
      // console.log('BILL QTY++', (this.props.billQty+1))
      if (this.props.billQty<10) {
        this.props.dispatch(change('BillingForm', 'billQty', ((isNaN(this.props.billQty) ? 0 : parseInt(this.props.billQty)) +1)))
      }
    } else {
      // console.log('BILL QTY--', (this.props.billQty-1))
      if (this.props.billQty>1) {
        this.props.dispatch(change('BillingForm', 'billQty', ((isNaN(this.props.billQty) ? 0 : parseInt(this.props.billQty)) -1)))
      }
    }
  }

  render() {
    const { showPasswd }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.billingStore.updateBilling!=undefined ? this.props.billingStore.updateBilling : {}) : (this.props.billingStore.createBilling!=undefined ? this.props.billingStore.createBilling : {}))
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Header as='h5'>
              <Icon name='file outline' style={{fontSize: '1em'}}/>
              <Header.Content>Bill Summary</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='isPaid' component={SelectField}
                            options={yesnoOptions()}
              width={4}
              label='Payment Status' />
              <Field name='billCode' component={InputField}
                            width={4}
              label='Bill Code'
              placeholder='Bill Code' />
              <Field name='billDate' component={DatePickerField}
                            width={4}
              label='Bill Date'
              placeholder='Bill Date'/>
              <Field name='dueDate' component={DatePickerField}
                            width={4}
              label='Payment Due Date'
              placeholder='Payment Due Date' />
            </Form.Group>
            
            {/* <Divider style={{margin: '2em 0 1.5em 0'}}/> */}
            <Form.Group widths='equal'>
              <Field name='billAmount' component={NumberField}
                            label='Gross Amt.'
              placeholder='Gross Amt.'/>
              <Field name='billDiscount' component={NumberField}
                            label='Discount Amt.'
              placeholder='Discount Amt.' />
              <Field name='billNettAmount' component={NumberField}
                            label='Nett Amt.'
              placeholder='Nett Amt.' />
              <Field name='dueAmount' component={NumberField}
                            label='Incl. VAT Amt.'
              placeholder='Incl. VAT Amt.' />
            </Form.Group>
          </Segment>
          <Segment>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1'}}>
                <Header as='h5'>
                  <Icon name='file alternate outline' style={{fontSize: '1em'}}/>
                  <Header.Content>Bill Information</Header.Content>
                </Header>
              </div>
              <div>
                <Button type='button' icon='minus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrBillQty.bind(this,false)}/>
              </div>
              <div>
                  <Field name='billQty' component={InputField} size='small' style={{height: '2.2em', width: '3em', borderRadius: '0'}}
                                    />
              </div>
              <div>
                <Button type='button' icon='plus' size='small'  style={{height: '2.2em', width: '2.5em', borderRadius: '0'}} color='grey' onClick={this.incrBillQty.bind(this,true)}/>
              </div>
            </div>
            <Form.Group widths='equal'>
              <Field name='billInfo' component={InputField}
                            label='Bill Info'
              placeholder='Bill Info' />
            </Form.Group>
            {/* <Form.Group widths='equal'>
              <Field name='billItems' component={InputField}
                            readOnly
              label='Bill Items'
              placeholder='Bill Items' />
            </Form.Group> */}

            <Header as='h5' color='blue'>Bill Items</Header>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell width='5'>
                    <Header as='h5' color='blue'>
                      ITEM
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='3'>
                    <Header as='h5' color='blue'>
                      PRICE
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='3'>
                    <Header as='h5' color='blue'>
                      DISCOUNT
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='1'>
                    <Header as='h5' color='blue'>
                      QUANTITY
                    </Header>
                  </Table.HeaderCell>
                  <Table.HeaderCell width='4'>
                    <Header as='h5' color='blue'>
                      SUBTOTAL
                    </Header>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.billItems(this.props.billQty)
                }
                {/* <Table.Row>
                  <Table.Cell colSpan={5}>
                    
                  </Table.Cell>
                  <Table.Cell>
                    <Form.Group widths='equal'>
                      <Field name='itemTotal_1' component={InputField}
                                            placeholder='Total' />
                    </Form.Group>
                  </Table.Cell>
                </Table.Row> */}
              
              </Table.Body>
            </Table>
          </Segment>
          
          <Segment attached='top'>
              <Header as='h5'>
                <Icon name='user outline' style={{fontSize: '1em'}}/>
                <Header.Content>Customer</Header.Content>
              </Header>
              <Form.Group widths='16'>
                <Field name='name' component={InputField}
                                width={8}
                label='Name'
                placeholder='Name' />
                <Field name='mobile' component={InputField}
                width={4}
                label='Mobile'
                placeholder='Handphone' />
                <Field name='email' component={InputField}
                width={4}
                label='Email'
                placeholder='Email' />
              </Form.Group>
          </Segment>
          
          <Segment>
            <Header as='h5'>
              <Icon name='building outline' style={{fontSize: '1em'}}/>
              <Header.Content>Corporate</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='company' component={InputField}
                            width={8}
              label='Company'
              placeholder='Company' />
              <Field name='brand' component={InputField}
                            width={4}
              label='Brand / Code'
              placeholder='Brand / Code' />
              <Field name='business' component={InputField}
                            width={4}
              label='Business Stream'
              placeholder='Business Stream' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
                            label='Address'
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='city' component={InputField}
              width={6}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={6}
              placeholder='Province' />
              <Field name='country' component={InputField}
              width={4}
              placeholder='Country' />
            </Form.Group>
          </Segment>

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Billing' : 'Insert Billing') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.billingStore.getBilling!=undefined && state.billingStore.getBilling.progress==1 ? state.billingStore.getBilling.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      billQty: selector(state, 'billQty'),
    }),
    { load: getBilling }
  )
  (reduxForm({
    form: 'BillingForm',	// a unique identifier for this form
  })(BillingForm)
  )
)

import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
  Checkbox
} from 'semantic-ui-react'
import LineChart from '../chart/LineRechart';

import { connect } from "react-redux"
import { parse, format, addSeconds } from "date-fns"

import { browseSyslog } from "../../actions/halo/syslogAction";
import { perfAgent } from "../../actions/halo/performanceAction";

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    syslogStore: state.syslogStore,
    performanceStore: state.performanceStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseSyslog:(objParam) => dispatch(browseSyslog(objParam)),
    perfAgent:(objParam) => dispatch(perfAgent(objParam)),
  }
};

class PerformanceAgent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      userId: 0,
      
      viewSimple: true,

      logSession: true,
      logLocking: true,
      logDispatch: false,
    };
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.performanceStore.perfAgent!==undefined ? this.props.performanceStore.perfAgent : {})
    this.props.perfAgent({search: {period: format(new Date(), 'yyyyMM'), ...search, groupId: 'Agent'}, paging: paging})
    this.props.browseSyslog({search: {period: format(new Date(), 'yyyy-MM-dd')}});
  }
  
  formatSeconds(seconds) {
    const date= addSeconds(new Date(0, 0, 0, 0, 0, 0), seconds);
    // console.log(date)
    return format(date, 'HH:mm:ss');
  }

  simpleView(item) {
    const actions= [];

    item && item.activities.map(log=>{
      if (log.action.match(/signing/i)) { //signing action
        if (log.action.match(/ in/i)) {
          actions.push({
            stampIn: log.stamp,
            stampOut: null,
            action: log.action+ ' / Out',
            reason: log.reason,
          })
        } else if (log.action.match(/ out/i)) {
          let found= false;
          actions.map(item=>{
            if (!found && item.action.match(/signing/i) && !item.stampOut) {
              item.stampOut= log.stamp;
              item.reason= log.reason;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            }
          })
        }
      } else if (log.action.match(/session/i)) {
        if (log.action.match(/start/i)) {
          actions.push({
            stampIn: log.stamp,
            stampOut: null,
            action: log.action+ ' / Exiting',
            reason: log.reason,
          })
        } else if (log.action.match(/exit/i)) {
          let found= false;
          actions.map(item=>{
            if (!found && item.action.match(/session/i) && !item.stampOut) {
              item.stampOut= log.stamp;
              item.reason= log.reason;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            }
          })
        }
      } else if (log.action.match(/locked/i)) {
        if (log.action.match(/enter/i)) {
          actions.push({
            stampIn: log.stamp,
            stampOut: null,
            action: log.action+ ' / Exiting',
            reason: log.reason,
          })
        } else if (log.action.match(/exit/i)) {
          let found= false;
          actions.map(item=>{
            if (!found && item.action.match(/locked/i) && !item.stampOut) {
              item.stampOut= log.stamp;
              item.reason= log.reason;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            }
          })
        }
      } else if (log.action.match(/dispatch/i)) {
        if (log.action.match(/start/i)) {
          actions.push({
            stampIn: log.stamp,
            stampOut: null,
            action: log.action+ ' / Exiting',
            reason: log.reason,
          })
        } else if (log.action.match(/exit/i)) {
          let found= false;
          actions.map(item=>{
            if (!found && item.action.match(/dispatch/i) && !item.stampOut) {
              item.stampOut= log.stamp;
              // item.reason= log.reason;
              item.duration= Math.round((parse(item.stampOut, 'HH:mm:ss', new Date())-parse(item.stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            }
          })
        }
      }
    })

    return(actions);
  }

  calculateDuration(item) {
    const actions= [];
    
    item && item.activities.map(log=>{
      if (log.action.match(/signing/i)) { //signing action
        if (log.action.match(/ in/i)) {
          actions.push({
            stamp: log.stamp,
            action: log.action,
          })
        } else if (log.action.match(/ out/i)) {
          let found= false;
          let stampIn;

          actions.push({
            stamp: log.stamp,
            action: log.action,
            reason: log.reason,
          })

          actions.map(item=>{
            if (item.action.match(/signing/i) && item.action.match(/ in/i)) {
              stampIn= item.stamp;
            }
            if (!found && item.action.match(/signing/i) && item.action.match(/ out/i) && !item.duration) {
              item.duration= stampIn && Math.round((parse(item.stamp, 'HH:mm:ss', new Date())-parse(stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            } else if (!found && item.action.match(/signing/i) && item.action.match(/ out/i) && item.duration!=undefined) {
              stampIn= null;
            }
          })
        }
      } else if (log.action.match(/session/i)) {
        if (log.action.match(/start/i)) {
          actions.push({
            stamp: log.stamp,
            action: log.action,
          })
        } else if (log.action.match(/exit/i)) {
          let found= false;
          let stampIn;

          actions.push({
            stamp: log.stamp,
            action: log.action,
            reason: log.reason,
          })

          actions.map(item=>{
            if (item.action.match(/session/i) && item.action.match(/start/i)) {
              stampIn= item.stamp;
            }
            if (!found && item.action.match(/session/i) && item.action.match(/exit/i) && !item.duration) {
              item.duration= stampIn && Math.round((parse(item.stamp, 'HH:mm:ss', new Date())-parse(stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            } else if (!found && item.action.match(/session/i) && item.action.match(/exit/i) && item.duration!=undefined) {
              stampIn= null;
            }
          })
        }
      } else if (log.action.match(/locked/i)) {
        if (log.action.match(/enter/i)) {
          actions.push({
            stamp: log.stamp,
            action: log.action,
            reason: log.reason,
          })
        } else if (log.action.match(/exit/i)) {
          let found= false;
          let stampIn;

          actions.push({
            stamp: log.stamp,
            action: log.action,
            reason: log.reason,
          })

          actions.map(item=>{
            if (item.action.match(/locked/i) && item.action.match(/enter/i)) {
              stampIn= item.stamp;
            }
            if (!found && item.action.match(/locked/i) && item.action.match(/exit/i) && !item.duration) {
              item.duration= stampIn && Math.round((parse(item.stamp, 'HH:mm:ss', new Date())-parse(stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            } else if (!found && item.action.match(/locked/i) && item.action.match(/exit/i) && item.duration!=undefined) {
              stampIn= null;
            }
          })
        }
      } else if (log.action.match(/dispatch/i)) {
        if (log.action.match(/start/i)) {
          actions.push({
            stamp: log.stamp,
            action: log.action,
          })
        } else if (log.action.match(/exit/i)) {
          let found= false;
          let stampIn;

          actions.push({
            stamp: log.stamp,
            action: log.action,
            reason: log.reason,
          })

          actions.map(item=>{
            if (item.action.match(/dispatch/i) && item.action.match(/start/i)) {
              stampIn= item.stamp;
            }
            if (!found && item.action.match(/dispatch/i) && item.action.match(/exit/i) && !item.duration) {
              item.duration= stampIn && Math.round((parse(item.stamp, 'HH:mm:ss', new Date())-parse(stampIn, 'HH:mm:ss', new Date()))/1000);
              found= true;
            } else if (!found && item.action.match(/dispatch/i) && item.action.match(/exit/i) && item.duration!=undefined) {
              stampIn= null;
            }
          })
        }
      }
    })

    return(actions);
  }

  onCheckbox(k, e, v) {
    const state= {};
    state[k]= v.checked;

    this.setState({
      ...state,
    })
  }

  onChangeSelect(e, v) {
    this.state.item && this.props.browseSyslog({search: {userId: this.state.item.agentId, period: v.value}});

    this.setState({
      dateActivity: v.value
    })
  }

  contentModal(item) {
    const { dateOptions, dateActivity, viewSimple, logSession, logLocking, logDispatch }= this.state;
    const { data }= (this.props.syslogStore.browseSyslog!==undefined ? this.props.syslogStore.browseSyslog : {})

    const regex= (logSession || logLocking || logDispatch) ? new RegExp(((logSession && 'signin|session|') || 'xyz|') + ((logLocking && 'locked|') || 'xyz|') + ((logDispatch && 'dispatch') || 'xyz'), 'i') : new RegExp(/ /, 'i');

    let dataExts;

    let seq= 0;

    if (viewSimple && data && data.length>0) {
      dataExts= this.simpleView(data[0]);
    } else if (data && data.length>0) {
      dataExts= this.calculateDuration(data[0]);
    }
    
    // console.log(item.period) //202101
    if (item.statTimers) {
      const dates= [];
      var startDate = parse(item.period + '01', 'yyyyMMdd', new Date()) //parse('20210101', 'yyyyMMdd', new Date())
      var endDate= parse(item.period + '01', 'yyyyMMdd', new Date()) //parse('20210101', 'yyyyMMdd', new Date())
      endDate.setMonth(endDate.getMonth()+1)
      
      while (startDate<endDate) {
        dates.push(format(startDate, 'dd'))
        startDate.setDate(startDate.getDate() + 1)
      }
      // console.log(startDate, endDate, dates)
      const chartLegend = [
        {id: 'totSignin', name: 'Signin'}, 
        {id: 'totEngaged', name: 'Contacted'}, 
        {id: 'totAchieved', name: 'Achieved'}, 
        {id: 'totLocked', name: 'Locked'}, 
      ]
      const chartData= [];
      item.statPerformances && dates.map(date=>{
        item.statPerformances[date.substring(0,2)] && console.log(date, item.statPerformances[date.substring(0,2)].totSignin, item.statPerformances[date.substring(0,2)].totEngaged, item.statPerformances[date.substring(0,2)].totAchieved, item.statPerformances[date.substring(0,2)].totLocked)
        // return(
          chartData.push({
            name: date,
            totSignin: (item.statPerformances[date.substring(0,2)] && parseInt(item.statPerformances[date.substring(0,2)].totSignin)) || 0,
            totEngaged: (item.statPerformances[date.substring(0,2)] && parseInt(item.statPerformances[date.substring(0,2)].totEngaged)) || 0,
            totAchieved: (item.statPerformances[date.substring(0,2)] && parseInt(item.statPerformances[date.substring(0,2)].totAchieved)) || 0,
            totLocked: (item.statPerformances[date.substring(0,2)] && parseInt(item.statPerformances[date.substring(0,2)].totLocked)) || 0,
          })
        // )
      })
      item['chart']= {
        data: chartData,
        legend: chartLegend,
      }
    }

    return(
      <div style={{padding: '1em'}}>
        <div style={{display: 'flex'}}>
          {item.statTimers && 
          <div style={{paddingRight: '2em', borderRight: '1px solid #666'}}>
            <Header as='h3' color='red'>
              <Header.Content>
                {format(new Date(parse(item.period + format(new Date(), 'dd'), 'yyyyMMdd', new Date())), 'dd/MMM, yyyy')}
                <Header.Subheader>To Date Performance</Header.Subheader>
              </Header.Content>
            </Header>
          </div>}
          <div style={{flex: '1', paddingLeft: '2em'}}>
              <Label color='purple' style={{borderRadius: '2em', padding: '.8em 1em .8em 1em'}}>
                <Header as='h3' inverted>
                  <Header.Content>
                    {item && item.name} / {item.tagger || '-'}
                    {/* <Header.Subheader>{item && item.client}</Header.Subheader> */}
                  </Header.Content>
                </Header>
              </Label>
          </div>
        </div>
        <Divider />
        <Segment>
          {!item.statTimers && 
            <Header as='h3' color='red'>
              <Header.Content>
                <Icon name='warning sign' />No data is available !
              </Header.Content>
            </Header>
          }
          {/* data={barSubscriptions} legend={legends} */}
          {item.statTimers && 
          <LineChart data={item.chart.data} legend={item.chart.legend} mode='simple' height={300} yAxis={false} dashed={true} label={true}/>}
        </Segment>

        <Divider />
        <Segment>
          <div style={{display: 'flex'}}>
            <div style={{marginRight: '2em'}}>
              <Checkbox checked={logSession} label={<label><b>Session Log</b></label>} onClick={this.onCheckbox.bind(this, 'logSession')}/>
            </div>
            <div style={{marginRight: '2em'}}>
              <Checkbox checked={logLocking} label={<label><b>Locking Log</b></label>} onClick={this.onCheckbox.bind(this, 'logLocking')}/>
            </div>
            <div style={{flex: '1', marginRight: '2em'}}>
              <Checkbox checked={logDispatch} label={<label><b>Dispatch Log</b></label>} onClick={this.onCheckbox.bind(this, 'logDispatch')}/>
            </div>
            <div style={{paddingTop: '.4em'}}>
              <Checkbox checked={viewSimple} label={<label><b>Simple View</b></label>} onClick={this.onCheckbox.bind(this, 'viewSimple')}/>
            </div>
          </div>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue'>
                    TIME
                    <Header.Subheader>hh:mm</Header.Subheader>
                  </Header>
                </Table.HeaderCell>
                {viewSimple && <Table.HeaderCell width='1'>
                  <Header as='h5' color='blue'>
                    TIME
                    <Header.Subheader>hh:mm</Header.Subheader>
                  </Header>
                </Table.HeaderCell>}
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>
                    ACTIVITY
                    <Header.Subheader>Action Logger</Header.Subheader>
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={viewSimple ? 6 : 7}>
                  <Header as='h5' color='blue'>
                    REASON
                    <Header.Subheader>Action Reason</Header.Subheader>
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>
                    DURATION
                    <Header.Subheader>hh:mm:ss</Header.Subheader>
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!viewSimple && dataExts && dataExts.map(log=>{
                if (log.action.match(regex)) {
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell>
                        <Header as='h5' 
                          // color={
                          // (log.action.match(/signing/i) && log.action.match(/ in| out/i) && 'red') ||
                          // (log.action.match(/session/i) && log.action.match(/starting|exit/i) && 'blue') ||
                          // (log.action.match(/locked/i) && log.action.match(/enter|exit/i) && 'grey') ||
                          // (log.action.match(/dispatch/i) && log.action.match(/receiving|releasing/i) && 'brown')
                          // || 'black'}
                          >
                          <Header.Content>
                            {/* <Icon name={log.action.match(/out|exit|releasing/i) ? 'long arrow alternate left' : 'long arrow alternate right'} color={
                            (log.action.match(/signing/i) && 'red') ||
                            (log.action.match(/session/i) && 'blue') ||
                            (log.action.match(/locked/i)  && 'grey') ||
                            (log.action.match(/dispatch/i) && 'brown')
                            || 'black'} 
                            /> */}
                            {log.stamp && log.stamp.substr(0,5)}
                            {/* {log.stamp && log.stamp} */}
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>
                        <Header as='h5' 
                          // color={
                          // (log.action.match(/signing/i) && log.action.match(/ in| out/i) && 'red') ||
                          // (log.action.match(/session/i) && log.action.match(/starting|exit/i) && 'blue') ||
                          // (log.action.match(/locked/i) && log.action.match(/enter|exit/i) && 'grey') ||
                          // (log.action.match(/dispatch/i) && log.action.match(/receiving|releasing/i) && 'brown')
                          // || 'black'}
                          >
                          <Header.Content>
                            {log.action}
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {log.reason}
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                          <Header.Content>
                            {!isNaN(log.duration) && log.duration!='-' ? this.formatSeconds(log.duration||0).replace(/00/g,'--') : ''}
                            {/* {log.duration} */}
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>  
                  )
                }
              })}
              {viewSimple && dataExts && dataExts.map(log=>{
                if (log.action.match(regex)) {
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {log.stampIn && log.stampIn.substr(0,5)}
                            {/* {log.stampIn && log.stampIn} */}
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {log.stampOut && log.stampOut.substr(0,5)}
                            {/* {log.stampOut && log.stampOut} */}
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {log.action}
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {log.reason}
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5' style={{fontFamily: 'courier', fontSize: '1.2em'}}>
                          <Header.Content>
                            {!isNaN(log.duration) && log.duration!='-' ? this.formatSeconds(log.duration||0).replace(/00/g,'--') : ''}
                            {/* {log.duration} */}
                            <Header.Subheader></Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>  
                  )
                }
              })}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    )
  }

  render() {
    const { search, paging, data }= (this.props.performanceStore.perfAgent!==undefined ? this.props.performanceStore.perfAgent : {})

    return(
      <>
        {data && data[0] && this.contentModal(data[0])}
      </> 
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAgent)

import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import {genderOptions, maritalOptions, occupationOptions, revenueOptions, expenseOptions, homestayOptions, vehicleOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, getFormValues } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField } from '../validation/componentrsui'
import { required, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetContact, getContact,updateContact, createContact } from "../../actions/halo/contactAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    contactStore: state.contactStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetContact:(objParam) => dispatch(resetContact(objParam)),
    getContact:(objParam) => dispatch(getContact(objParam)),
    createContact:(objParam) => dispatch(createContact(objParam)),
    updateContact:(objParam) => dispatch(updateContact(objParam)),
  }
}

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      showPasswd: false,
    }
  }

  componentDidMount(){
    this.props.resetContact(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getContact({contactId: this.props.contactId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.contactStore.updateContact!=undefined ? this.props.contactStore.updateContact : {}) : (this.props.contactStore.createContact!=undefined ? this.props.contactStore.createContact : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    mode==2 ? this.props.updateContact({...values}) : this.props.createContact({...values})
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.contactStore.updateContact!=undefined ? this.props.contactStore.updateContact : {}) : (this.props.contactStore.createContact!=undefined ? this.props.contactStore.createContact : {}))
    
    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            {false && <div>
            <Form.Group widths='equal' >
              <Form.Field>
                <Field name='avatar' component={ImageUploadField}
                width={200} 
                height={200} 
                aspectRation={1}
                />
              </Form.Field>
            </Form.Group>
            <Divider hidden/>
            </div>
            }
            <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField}
              toggle
              width={8}
              caption='Enable Now !'
              placeholder='Status enable/disabled' />
              <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='id card outline' style={{fontSize: '1em'}}/>
              <Header.Content>Personal</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='name' component={InputField}
              width={13}
              label='Name'
              placeholder='Full Name' />
              <Field name='keyword' component={InputField}
              width={13}
              label='Keyword'
              placeholder='Keyword' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='gender' component={SelectField}
                            options={genderOptions}
              label='Gender'
              placeholder='L/P' />
              <Field name='birthDate' component={DatePickerField}
              label='Birth Date'
              placeholder='Birth Date' />
              <Field name='children' component={InputField}
              label='Children'
              placeholder='Children' />
              <Field name='marital' component={SelectField}
                            options={maritalOptions}
              label='Marital'
              placeholder='Marital' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
              <Header.Content>Address</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='city' component={InputField}
              placeholder='City' />
              <Field name='province' component={InputField}
              placeholder='Province' />
              <Field name='country' component={InputField}
              placeholder='Country' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='fax' style={{fontSize: '1em'}}/>
              <Header.Content>Contact</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='mobile' component={InputField}
              label='Mobile'
              placeholder='Handphone' />
              <Field name='homePhone' component={InputField}
              label='Home Phone'
              placeholder='Home Phone' />
              <Field name='officePhone' component={InputField}
              label='Office Phone'
              placeholder='Office Phone' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='email' component={InputField}
              label='Email'
              placeholder='Email' />
              <Field name='whatsapp' component={InputField}
              label='Whatsapp'
              placeholder='Whatsapp' />
              <Field name='emergencyPhone' component={InputField}
              label='Emergency Phone'
              placeholder='Emergency Phone' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='twitter' component={InputField}
                            label='Twitter'
              placeholder='Twitter' />
              <Field name='instagram' component={InputField}
                            label='Instagram'
              placeholder='Instagram' />
              <Field name='facebook' component={InputField}
                            label='Facebook'
              placeholder='Facebook' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Icon name='closed captioning outline' style={{fontSize: '1em'}}/>
              <Header.Content>Financial</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='occupation' component={SelectField}
              options={occupationOptions}
              label='Occupation'
              placeholder='Occupation' />
              <Field name='revenue' component={SelectField}
              options={revenueOptions}
              label='Revenue'
              placeholder='Revenue' />
              <Field name='expense' component={SelectField}
              options={expenseOptions}
              label='Expense'
              placeholder='Expense' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='homestay' component={SelectField}
              options={homestayOptions}
              label='Homestay'
              placeholder='Homestay' />
              <Field name='vehicle' component={SelectField}
              options={vehicleOptions}
              label='Vehicle'
              placeholder='Vehicle' />
              <Field name='creditcard' component={InputField}
              label='Credit Card'
              placeholder='Credit Card' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='jobTitle' component={InputField}
              label='Job Title'
              placeholder='Job Title' />
              <Field name='jobDepartment' component={InputField}
              label='Job Department'
              placeholder='Job Department' />
              <Field name='jobCompany' component={InputField}
              label='Job Company'
              placeholder='Job Company' />
            </Form.Group>
          </Segment>

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Contact' : 'Insert Contact') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.contactStore.getContact!=undefined && state.contactStore.getContact.progress==1 ? state.contactStore.getContact.data : {}),
      formValues: getFormValues('ContactForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    }),
    { load: getContact }
  )
  (reduxForm({
    form: 'ContactForm',	// a unique identifier for this form
  })(ContactForm)
  )
)

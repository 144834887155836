import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider, Radio, Button, Image, Label, Popup } from 'semantic-ui-react'

import TwitterMessage from '../socmed/TwitterMessage';
import TwitterCreation from '../socmed/TwitterCreation';
import TwitterList from '../socmed/TwitterList';
import TwitterNetwork from '../socmed/TwitterNetwork';

class TwitterBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }
    
    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      return(
        <div style={{display: 'flex', padding: '0 1em', minHeight: screenHeight}}>
          <div style={{flex: 1, background: '#f5f5f5', padding: '1em 1em'}}>
            <Header as='h5'>
              <Header.Content>
                Twitter V2 API
                <Header.Subheader>
                  Twitter API Conversation, Creation, List and Users.
                  https://developer.twitter.com/en/portal/products/
                </Header.Subheader>
              </Header.Content>
            </Header>
            <Divider />

            {mode==0 && <div style={{textAlign: 'center', paddingTop: screenHeight/2}}><Icon name='twitter' style={{fontSize: '32em', color: '#fff'}} /></div>}
            {mode==1 && <TwitterMessage />}
            {mode==2 && <TwitterCreation />}
            {mode==3 && <TwitterList />}
            {mode==4 && <TwitterNetwork />}
          </div>
          <div style={{paddingLeft: '0', background: '#f0f0f0', padding: '1em 1em'}}>
            <Popup content='Twitter Conversation' position='left center' inverted trigger={<Label color={mode==1 ? 'blue' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 1)}><Icon name='comments' size='large' style={{margin: '0'}} /></Label>} />
            <Divider />
            <Popup content='Twitter Creation' position='left center' inverted trigger={<Label color={mode==2 ? 'blue' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 2)}><Icon name='send' size='large' style={{margin: '0'}} /></Label>} />
            <Divider />
            {/* <Popup content='Twitter List/Group' position='left center' inverted trigger={<Label color={mode==3 ? 'blue' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 3)}><Icon name='clone' size='large' style={{margin: '0'}} /></Label>} />
            <Divider /> */}
            <Popup content='Twitter Follower' position='left center' inverted trigger={<Label color={mode==4 ? 'blue' : null} size='large' as='a' circular onClick={this.setMode.bind(this, 4)}><Icon name='users' size='large' style={{margin: '0'}} /></Label>} />
          </div>
        </div>
      )
    }
}

export default TwitterBody

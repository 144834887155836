import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Feed, Divider, Label } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';
import MyBucketPipeline from '../../libraries/agent/ticket/MyBucketPipeline';

class MyBucketPipelineBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    };
  }
  
  render() {
    const {mode, layout, ticketId, param, bucket, filter, workspace, stamp}= this.props
    const {screenWidth, screenHeight}= this.props
    
    return(
      <MyBucketPipeline filter={{...filter, scope: workspace}} screenWidth={screenWidth} screenHeight={screenHeight-125} setMode={this.props.setMode} openModal={this.props.openModal} closeModal={this.props.closeModal} />
    )
  }
}

export default MyBucketPipelineBody

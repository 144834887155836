import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image } from 'semantic-ui-react'

export default class MenuLeftOutbound extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      
    };
  }

  render() {
    const { screenWidth,  screenHeight, mini, module}= this.props
    
    if (mini) {
      return(
        <></>
      )
    } else {
      return (
        <div style={{top:'3em', position: 'fixed', height: screenHeight, width: '80px', zIndex: 99, background: '#dadadaaa'}}>
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '80px', padding: '3.5em 1.2em 0 0'}}>
            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Dashboard' content='Real time monitoring channel, traffic and system heartbeat.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'dashboard')}
                  color={this.props.mode=='dashboard' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='dashboard' ? '600' : '100'}}>
                    <Icon name='chart bar' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='dashboard' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div>
              
            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Interaction' content='Engagement over multi channel as conversation activity.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'conversation')}
                  color={this.props.mode=='conversation' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='host' ? '600' : '100'}}>
                    <Icon name='comments outline' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='conversation' ? 'blue' : 'grey'}/>
                </Header>
              } />
              
              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Completed' content='Achieved conversation as 100% score of remark or feedback is covered.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'activity')}
                  color={this.props.mode=='activity' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='activity' ? '600' : '100'}}>
                    <Icon name='envelope open outline' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='activity' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div>

            <div style={{textAlign: 'center', background: 'rgba(255, 255, 255, .5)', margin: '1em 0 0 1em', padding: '1em 0', borderRadius: '.5em', border: '1px solid #d6d6d6'}}>
              <Popup header='Campaign Setup' content='Campaign management and target allocation.' position='right center' inverted trigger={
                <Header as='h3' icon inverted
                  onClick={this.props.openWindow.bind(this,'event')}
                  color={this.props.mode=='event' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='event' ? '600' : '100'}}>
                    
                    <Icon name='calendar alternate outline' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='event' ? 'blue' : 'grey'}/>
                </Header>
              } />

              <Divider style={{margin: '1em  0'}}/>
              <Popup header='Resources' content='Manage private and customer resources for campaigns usage.' position='right center' inverted trigger={
                <Header as='h3' inverted icon
                  onClick={this.props.openWindow.bind(this,'resource')}
                  color={this.props.mode=='resource' ? null : 'grey'}
                  style={{margin: '0', cursor: 'pointer', fontWeight: this.props.mode=='resource' ? '600' : '100'}}>
                    <Icon name='hdd outline' style={{margin: '0', fontSize: '1.3em'}} color={this.props.mode=='resource' ? 'blue' : 'grey'}/>
                </Header>
              } />
            </div>
          </div>
        </div>
      );
    }
  }
}
